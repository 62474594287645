<template>
  <section
    class="bg-black w-full mx-auto max-w-7xl p-6 md:p-12 lg:p-20 rounded-2xl min-h-[425px] flex flex-col justify-center relative"
    data-scroll-section
  >
    <div class="overflow-hidden">
      <img
        src="@/assets/face-white.svg"
        alt="Neptune Face"
        class="absolute top-[10px] left-[-100px] opacity-15"
        data-scroll
        data-scroll-speed="0.08"
      />
    </div>
    <h1
      class="text-white max-w-[725px] mx-auto text-center text-lg sm:text-[24px] lg:text-[32px] font-wide font-extrabold tracking-[2px] uppercase opacity-0 translate-y-8 leading-tight"
      data-test="callToAction.title"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.2,
              ease: 'expo',
            })
          }
        }
      "
    >
      SERIOUSLY, OUR TECHNOLOGY is EASIER, FASTER, AND BETTER.
    </h1>
    <form
      class="text-center mt-6 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.3,
              ease: 'expo',
            })
          }
        }
      "
    >
      <div class="mx-auto max-w-[552px] w-full relative">
        <QuoteForm :baseUrl="props.baseUrl" />
      </div>
    </form>
  </section>
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import { onMounted } from 'vue'
import LocomotiveScroll from 'locomotive-scroll'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import QuoteForm from '@/components/QuoteForm.vue'
const props = defineProps({
  baseUrl: {
    default: 'https://dev.neptuneflood.com',
  },
  productType: {
    default: 'residential',
  },
})

onMounted(() => {
  const scroll = new LocomotiveScroll()
  console.log(scroll)
})
</script>
