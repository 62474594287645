<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.05]"
  />

  <section class="mx-auto max-w-7xl py-20 px-6 relative">
    <div class="">
      <h2
        class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[589px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        Marketing Assets
      </h2>
      <p
        class="max-w-[718px] text-neutral-600 opacity-0 translate-y-8 mb-6"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        The Neptune logo can be used on your website to showcase that you offer
        our products. However, unless Neptune approves, the logo should not be
        used to create your own marketing materials for products.
      </p>
      <p
        class="max-w-[718px] text-neutral-600 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        Neptune's primary logo is blue, horizontal, with the Neptune man to the
        left. If blue does not work with the color scheme of your website or
        approved material, you may use white or black. In some instances, a
        vertical logo may be a better fit. The vertical logo should only be used
        sparingly.
      </p>
    </div>
    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-8">
      <div v-for="asset in assets">
        <div
          class="rounded-md aspect-square flex justify-center items-center p-8 relative"
          :class="{
            'bg-brand-air': asset.background === 'air',
            'bg-brand-blue': asset.background === 'blue',
          }"
        >
          <img :src="asset.png" alt="" class="max-h-[250px]" />
          <div
            class="absolute bottom-0 inset-x-0 space-x-2 p-4 justify-center flex"
          >
            <a
              :href="asset.png"
              download
              target="_blank"
              class="rounded-full px-4 py-2 text-center inline-flex items-center space-x-2 bg-black text-white text-xs hover:bg-black/75 transition"
              ><span>.png</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z"
                />
                <path
                  d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z"
                />
              </svg>
            </a>
            <a
              :href="asset.eps"
              download
              target="_blank"
              class="rounded-full px-4 py-2 text-center inline-flex items-center space-x-2 bg-black text-white text-xs hover:bg-black/75 transition"
              ><span>.eps</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z"
                />
                <path
                  d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z"
                />
              </svg>
            </a>
            <a
              :href="asset.svg"
              download
              target="_blank"
              class="rounded-full px-4 py-2 text-center inline-flex items-center space-x-2 bg-black text-white text-xs hover:bg-black/75 transition"
              ><span>.svg</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z"
                />
                <path
                  d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2
        class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[589px] leading-snug mb-5 opacity-0 translate-y-8 uppercase mt-20"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        Colors
      </h2>
      <div class="grid grid-cols-3 lg:grid-cols-6">
        <div
          v-for="color in colors"
          v-html="color.text"
          :style="{
            background: color.backgroundColor,
            color: color.textColor,
          }"
          class="p-4 leading-0 font-bold text-sm uppercase"
        ></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import logoHorizontalBlackEps from '@/assets/logo-horizontal-black.eps'
import logoHorizontalBlackPng from '@/assets/logo-horizontal-black.png'
import logoHorizontalBlackSvg from '@/assets/logo-horizontal-black.svg'
import logoHorizontalBlueEps from '@/assets/logo-horizontal-blue.eps'
import logoHorizontalBlueSvg from '@/assets/logo-horizontal-blue.svg'
import logoHorizontalBluePng from '@/assets/logo-horizontal-blue.png'
import logoHorizontalWhiteEps from '@/assets/logo-horizontal-white.eps'
import logoHorizontalWhitePng from '@/assets/logo-horizontal-white.png'
import logoHorizontalWhiteSvg from '@/assets/logo-white.svg'

import logoBlackEps from '@/assets/logo-black.eps'
import logoBlackPng from '@/assets/logo-black.png'
import logoBlackSvg from '@/assets/logo-black-copy.svg'
import logoBlueEps from '@/assets/logo-blue.eps'
import logoBluePng from '@/assets/logo-blue.png'
import logoBlueSvg from '@/assets/logo-blue-copy.svg'
import logoWhiteEps from '@/assets/logo-white.eps'
import logoWhitePng from '@/assets/logo-white.png'
import logoWhiteSvg from '@/assets/logo-white.svg'

import { reactive, ref } from 'vue'
const { data } = defineProps(['data'])

const assets = [
  {
    background: 'air',
    eps: logoHorizontalBlueEps,
    png: logoHorizontalBluePng,
    svg: logoHorizontalBlueSvg,
  },
  {
    background: 'blue',
    eps: logoHorizontalWhiteEps,
    png: logoHorizontalWhitePng,
    svg: logoHorizontalWhiteSvg,
  },
  {
    background: 'air',
    eps: logoHorizontalBlackEps,
    png: logoHorizontalBlackPng,
    svg: logoHorizontalBlackSvg,
  },

  {
    background: 'air',
    eps: logoBlueEps,
    png: logoBluePng,
    svg: logoBlueSvg,
  },
  {
    background: 'blue',
    eps: logoWhiteEps,
    png: logoWhitePng,
    svg: logoBlueSvg,
  },
  {
    background: 'air',
    eps: logoBlackEps,
    png: logoBlackPng,
    svg: logoBlueSvg,
  },
]

const colors = [
  {
    backgroundColor: 'black',
    textColor: 'gray',
    text: `<div>JET BLACK</div>
  <div>PMS: Black 3 C</div>
  <div>HEX: 000000</div>
  <div>R 0 G 0 B 0</div>
  <div>C 100 M 100 Y 100 K 100</div>`,
  },
  {
    backgroundColor: '#004C9D',
    textColor: 'white',
    text: `<div>Deep Sea</div>
  <div>PMS: 2945 C</div>
  <div>HEX: 004C9D</div>
  <div>R 0 G 76 B 157</div>
  <div>C 100 M 80 Y 5 K 0</div>`,
  },
  {
    backgroundColor: '#FFFFFF',
    textColor: '#004C9D',
    text: `<div>Bright White</div>
  <div>HEX: FFFFFF</div>
  <div>R 255 G 255 B 255</div>
  <div>C 0 M 0 Y 0 K 0</div>`,
  },
  {
    backgroundColor: '#97999B',
    textColor: 'black',
    text: `<div>MIST</div>
  <div>PMS: Cool Grey 7 C</div>
  <div>HEX: 97999B</div>
  <div>R 151 G 153 B 155</div>
  <div>C 44 M 35 Y 34 K 1</div>`,
  },
  {
    backgroundColor: '#E0EAF3',
    textColor: '#004C9D',
    text: `<div>LIGHT AIR</div>
  <div>PMS: 50% of 545 C</div>
  <div>HEX: E0EAF3</div>
  <div>R 244 G 234 B 243</div>
  <div>C 11 M 4 Y 2 K 0</div>`,
  },
  {
    backgroundColor: '#6AAFAA',
    textColor: '#004C9D',
    text: `<div>SEA FOAM</div>
  <div>PMS: 2460 C</div>
  <div>HEX: 6AAFAA</div>
  <div>R 106 G 175 B 170</div>
  <div>C 61 M 15 Y 35 K 0</div>`,
  },
]
</script>
