<template>
  <!--  <div class="mt-24 pb-24">
    <h2 class="text-[24px] text-brand-green">Frequently asked questions</h2>
    <div class="mt-6 divide-y border-b border-gray-100">
      <div v-for="n in 5" class="py-6">
        <div
          class="flex justify-between items-center hover:bg-gray-100 rounded -mx-4 p-4 cursor-pointer"
        >
          <h3 class="text-base m-0 font-bold">
            Who will we let use the facility?
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </div>

        <p class="mt-2 mb-4">
          Embarc Collective welcomes elected officials and high-profile visitors
          of all political parties and perspectives to host their events at our
          facility. Embarc Collective uses its discretion when booking visitors
          based on availability and other factors, and Embarc Collective has the
          ultimate determination over who can rent space and when.
        </p>
      </div>
    </div>
  </div> -->

  <div>
    <dl class="mt-6">
      <Disclosure
        as="div"
        v-for="faq in data?.faqs?.faqs ||
        data?.faqs || [
          { question: 'test', answer: 'test', open: false },
          { question: 'test', answer: 'test', open: false },
          { question: 'test', answer: 'test', open: false },
          { question: 'test', answer: 'test', open: false },
          { question: 'test', answer: 'test', open: false },
        ]"
        :key="faq.question"
        v-slot="{ open }"
        class="w-full border-b border-gray-200 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.2,
                ease: 'expo',
              })
            }
          }
        "
      >
        <dt class="w-full">
          <DisclosureButton
            class="flex justify-between items-center hover:bg-gray-100 rounded cursor-pointer w-full py-[20px]"
          >
            <span
              class="text-base m-0 font-extrabold tracking-[.16px] leading-[1.5] text-[#1C1917] text-left uppercase"
              >{{ faq.question || faq.headline }}</span
            >
            <span class="ml-6 flex h-7 items-center">
              <ChevronDownIcon
                :class="[
                  open ? '-rotate-180' : 'rotate-0',
                  'h-6 w-6 transform text-brand-purple',
                ]"
                aria-hidden="true"
              />
            </span>
          </DisclosureButton>
        </dt>
        <DisclosurePanel as="dd" class="pr-12 pb-[10px]">
          <div
            v-html="faq.answer || faq.body"
            class="mt-2 mb-4 prose text-brand-black prose-a:text-brand-green prose-a:hover:text-brand-green/75 !max-w-none"
          ></div>
        </DisclosurePanel>
      </Disclosure>
    </dl>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import { defineProps } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { vIntersectionObserver } from '@vueuse/components'

const { data } = defineProps(['data'])
</script>
