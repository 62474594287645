const typesArray = [
  { type: "Sole Proprietorship", id: 1 },
  { type: "C Corporation", id: 2 },
  { type: "S Corporation", id: 3 },
  { type: "Partnership", id: 4 },
  { type: "Trust or Estate", id: 5 },
  { type: "LLC C Corporation", id: 6 },
  { type: "LLC S Corporation", id: 7 },
  { type: "LLC Partnership", id: 8 },
  { type: "Individual", id: 9 },
  { type: "Other", id: 10 }
];
export default typesArray;
