import * as yup from "yup";
const PhoneRegex = /\d{3}-\d{3}-\d{4}/;
const ZipRegex = /^\d{5}(?:[- ]?\d{4})?$/;
const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const createBaseSchema = (subsRequireRemoteReference) => {
  return yup.object({
    email: yup
      .string()
      .required("Email is required")
      .matches(EmailRegex, "Please enter a valid email"),
    phone: yup
      .string()
      .required("Phone is required")
      .matches(PhoneRegex, "Phone Number is not valid"),
    name: yup.string().required("Name is required"),
    title: yup.string(),
    streetAddress: yup.string().required("Address 1 is required"),
    mailingStreetAddress: yup.string().required("Mailing address 1 is required"),
    address2: yup.string(),
    mailingAddress2: yup.string(),
    state: yup.string().required("State is required"),
    mailingState: yup.string().required("Mailing state is required"),
    zipcode: yup
      .string()
      .required("Zip code is required")
      .matches(ZipRegex, "Please enter a valid zip code"),
    mailingZipcode: yup
      .string()
      .required("Mailing zip code is required")
      .matches(ZipRegex, "Please enter a valid mailing zip code"),
    city: yup.string().required("City is required"),
    mailingCity: yup.string().required("Mailing city is required"),
    agencyName: yup
      .string()
      .max(40, "Max length of 40 characters is exceeded")
      .required("Agency Name is required"),
    verificationMethod: yup.string(),
    verificationCode: yup.string(),
    remoteReferenceNo:
      subsRequireRemoteReference.value === true
        ? yup.string().required("Remote reference number is required")
        : yup.string()
  });
};
