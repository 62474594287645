<template>
  <div class="relative">
    <input
      type="text"
      class="bg-white rounded-full px-5 text-sm sm:text-base placeholder:text-gray-500 placeholder:top-[1px] placeholder:relative w-full py-4"
      data-test="quoteForm.addressInput"
      placeholder="Enter Address, City, State, Zip"
      v-model="searchQuery"
      @input="handleInputChange"
    />
    <ul
      v-if="suggestions.length > 0 && !selectedFromSuggestions"
      class="text-left left-[10px] top-[101%] absolute top-full bg-white rounded-lg z-10 shadow-sm border border-gray-200 p-1"
      v-on-click-outside="
        () => {
          suggestions = []
        }
      "
    >
      <li
        v-for="suggestion in suggestions"
        class="hover:bg-gray-100 px-1 py-1.5 rounded-lg cursor-pointer"
        data-test="quoteForm.addressSuggestion"
        @click="selectSuggestion(suggestion)"
      >
        <span class="relative top-[1px]">
          <span
            >{{ suggestion.street_line }} {{ suggestion.city }}
            {{ suggestion.state }} {{ suggestion.zipcode }}</span
          >

          <span v-if="suggestion.entries">
            ({{ suggestion.entries }} entries)</span
          >
        </span>
      </li>
    </ul>
  </div>
  <button
    class="rounded-full bg-brand-blue pl-4 pr-2.5 absolute right-0 top-1/2 -translate-y-1/2 font-bold text-sm mr-1.5 py-[14px] text-white hover:bg-brand-blue/75 transition hidden sm:inline-block"
    data-test="quoteForm.addressSubmit"
    @click="handleSubmit"
    @submit.prevent=""
  >
    <span class="relative inline-flex space-x-1.5 uppercase">
      <span class="relative top-[1px] tracking-[1px]">Get Quote</span>
      <ArrowRightIcon class="w-5"
    /></span>
  </button>
  <div class="sm:hidden">
    <button
      class="rounded-full text-base md:text-sm px-6 md:pl-3.5 md:pr-2.5 font-bold mr-1.5 py-3 text-white md:hover:bg-brand-blue/75 transition text-center mt-4 uppercase tracking-[1px]"
      :class="props.buttonColor"
      data-test="quoteForm.addressSubmit"
      @click="handleSubmit"
      @submit.prevent=""
    >
      <span class="inline-flex space-x-1.5">
        <span class="relative top-[1px]">Get Quote</span>
        <ArrowRightIcon class="w-5"
      /></span>
    </button>
  </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import { ref, watch, defineProps } from 'vue'

const searchQuery = ref('')
const suggestions = ref('')
const selectedFromSuggestions = ref(false)
const props = defineProps({
  baseUrl: {
    default: 'https://dev.neptuneflood.com',
  },
  productType: {
    default: 'residential',
  },
  buttonColor: { default: 'bg-brand-blue hover:bg-brand-blue/75' },
})

// Handle input change to reset the selectedFromSuggestions flag when needed
const handleInputChange = () => {
  // Reset only if the user modifies the input after selecting a suggestion
  if (selectedFromSuggestions.value) {
    selectedFromSuggestions.value = false
  }
}

const selectSuggestion = (suggestion) => {
  searchQuery.value = `${suggestion.street_line} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`
  suggestions.value = [] // Optionally clear suggestions after selection
  selectedFromSuggestions.value = true
}

const searchAddress = async (query) => {
  const params = new URLSearchParams({
    key: '19033897084078356',
    search: query,
    max_results: 10,
    prefer_geolocation: 'CITY',
    source: 'postal',
  })

  try {
    const response = await fetch(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?${params.toString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    return data.suggestions || []
  } catch (error) {
    console.error(error)
    return []
  }
}

// Debounce function to limit the rate of API calls
const debounce = (fn, delay) => {
  let timeoutId
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

// Debounced search function
const debouncedSearch = debounce(async (query) => {
  if (query) {
    suggestions.value = await searchAddress(query)
    console.log(suggestions) // Handle suggestions (e.g., update a list in your data)
  }
}, 500) // Adjust delay as needed

// Watcher on the searchQuery ref
watch(searchQuery, (newValue) => {
  debouncedSearch(newValue)
})

const propertyType = ref('primaryHome')
const isForIframe = ref(false)

const handleSubmit = () => {
  // Here we handle the form submission
  let address = searchQuery.value
  let urlParameters = new URLSearchParams()

  // Construct URL parameters
  urlParameters.set('address', address)
  // if (propertyType.value)
  //   urlParameters.set('property-type', encodeURIComponent(propertyType.value))

  // urlParameters.set('product-type', encodeURIComponent(props.productType))
  // urlParameters.set('new-quote', 'yes')

  // Retrieve UTM parameters and other parameters if they exist and add them to the URL parameters
  const currentUrlParams = new URLSearchParams(window.location.search)
  const allParams = [
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_campaign',
    'source',
    'payload',
    'gclid',
  ]
  allParams.forEach((param) => {
    if (currentUrlParams.has(param)) {
      urlParameters.set(param, currentUrlParams.get(param))
    }
  })

  // Construct the entry point URL
  let entryPointUrl = `${
    props.baseUrl
  }/consumer-app?${urlParameters.toString()}`

  window.open(entryPointUrl)
}

function getURLParameter(param) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}
</script>
