<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.05]"
  />

  <section class="mx-auto max-w-7xl py-20 px-6 relative">
    <div class="sm:text-center">
      <h1
        class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[589px] mx-auto leading-snug mb-5 opacity-0 translate-y-8 uppercase"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        Join Team Neptune
      </h1>
      <p
        class="max-w-[718px] mx-auto text-neutral-600 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        Neptune is always looking for ambitious, driven, extraordinary talent to
        join our growing team. If this sounds like you, we want to hear from
        you.
      </p>
      <div
        class="mt-6 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.15,
                ease: 'expo',
              })
            }
          }
        "
      >
        <a
          href="https://www.indeed.com/cmp/Neptune-Flood/jobs"
          target="_blank"
          class="bg-brand-blue text-white uppercase tracking-[1px] text-sm font-semibold py-4 px-8 rounded-full inline-block hover:bg-brand-blue/75 transition text-center"
          >View and Apply for Open Positions on Indeed</a
        >
      </div>
    </div>
  </section>

  <section
    class="grid md:grid-cols-3 max-w-7xl mx-auto md:gap-12 xl:gap-24 items-center my-20 px-6"
  >
    <div class="col-span-1 mb-8 md:mb-0">
      <img
        src="@/assets/team1.jpg"
        class="aspect-square rounded-2xl object-cover opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      />
    </div>
    <div class="col-span-2">
      <h2
        class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        WHO WE ARE
      </h2>
      <p
        class="text-neutral-600 opacity-0 translate-y-8 leading-relaxed"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        Founded in 2018, Neptune Flood is the preferred private flood insurance
        provider offering residential, commercial, and condominium insurance
        throughout the United States and is headquartered in downtown St.
        Petersburg, FL. Our effortless, intuitive, and on-demand platform allows
        agents and customers to quote and bind a policy in under two minutes,
        all built by our stellar in-house technology team. In October 2021,
        Neptune acquired Jumpstart, a parametric insurance company, adding
        parametric earthquake to our portfolio.
      </p>
    </div>
  </section>
  <section class="max-w-7xl mx-auto my-32 px-6">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      Top tier benefits
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8 leading-relaxed max-w-[524px]"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.1,
              ease: 'expo',
            })
          }
        }
      "
    >
      Aside from the perk of working with an out-of-this-world team, Neptune
      offers a variety of benefits, including:
    </p>
    <div class="grid md:grid-cols-2 mt-12 gap-4">
      <div
        class="text-center p-3 md:py-8 uppercase font-extrabold tracking-wide border border-neutral-300 rounded-lg opacity-0 translate-y-8"
        v-for="(benefit, i) in benefits"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: i * 0.02,
                ease: 'expo',
              })
            }
          }
        "
      >
        {{ benefit }}
      </div>
    </div>
  </section>

  <section
    class="max-w-screen-2xl mx-auto my-32 grid grid-cols-3 max-h-[200px] sm:max-h-[400px] lg:grid-cols-4 gap-4 overflow-hidden md:max-h-[900px] px-6"
    data-scroll-section
  >
    <div
      data-scroll
      data-scroll-speed="-0.16"
      data-scroll-position="start,end"
      class="space-y-4"
    >
      <img
        src="@/assets/team2.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />
      <img
        src="@/assets/team3.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />

      <img
        src="@/assets/team14.jpg"
        class="aspect-square rounded-2xl object-cover w-full"
      />
    </div>
    <div
      data-scroll
      data-scroll-speed="0.16"
      data-scroll-position="start,end"
      class="space-y-4 relative top-[-100px]"
    >
      <img
        src="@/assets/team5.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />
      <img
        src="@/assets/team13.jpg"
        class="aspect-square rounded-2xl object-cover w-full"
      />

      <img
        src="@/assets/team7.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />
    </div>
    <div
      data-scroll
      data-scroll-speed="-0.16"
      data-scroll-position="start,end"
      class="space-y-4"
    >
      <img
        src="@/assets/team8.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />
      <img
        src="@/assets/team9.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />

      <img
        src="@/assets/team10.jpg"
        class="aspect-square rounded-2xl object-cover w-full"
      />
    </div>
    <div
      data-scroll
      data-scroll-speed="0.16"
      data-scroll-position="start,end"
      class="space-y-4 relative top-[-100px] hidden lg:block"
    >
      <img
        src="@/assets/team11.jpg"
        class="aspect-square rounded-2xl object-cover w-full"
      />
      <img
        src="@/assets/team12.jpg"
        class="aspect-square rounded-2xl object-cover w-full"
      />

      <img
        src="@/assets/team6.png"
        class="aspect-square rounded-2xl object-cover w-full"
      />
    </div>
  </section>

  <section
    class="grid md:grid-cols-3 max-w-7xl mx-auto md:gap-12 xl:gap-32 items-center my-20 px-6"
  >
    <div
      class="col-span-1 mb-8 md:mb-0 text-center md:space-y-12 grid grid-cols-3 md:grid-cols-1 items-center gap-8"
    >
      <img
        src="@/assets/community1.png"
        class="opacity-0 translate-y-8 block mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      />
      <img
        src="@/assets/community2.png"
        class="opacity-0 translate-y-8 block mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      />
      <img
        src="@/assets/community3.png"
        class="opacity-0 translate-y-8 block mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      />
    </div>
    <div class="col-span-2">
      <h2
        class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        OUR COMMITMENT TO THE COMMUNITY
      </h2>
      <div
        class="text-neutral-600 opacity-0 translate-y-8 leading-relaxed prose"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        <p>
          Neptune is committed to protecting our communities and planet while
          making them a better place to live for all. Through Cool Effect, One
          Tree Planted, Tampa Bay Watch, and our partners, we’re working towards
          carbon neutrality in our business operations to combat the effects of
          climate change and also volunteering in our community. To do our part,
          we:
        </p>

        <ul>
          <li>
            Reduce our carbon footprint through Cool Effect offsets to cover our
            business operations and employee work travel.
          </li>
          <li>
            Purchased 940 trees to assist with reforestation in Florida through
            One Tree Planted.
          </li>
          <li>
            Launched our grant program in 2021, with $200,000 to Tampa Bay Watch
            for their oyster reef restoration program.
          </li>
          <li>
            Donated and assembled hundreds of meal kits with the St. Pete Free
            Clinic.
          </li>
          <li>
            Provide paid time off to employees to volunteer to have a hands-on
            impact.
          </li>
          <li>
            Choose responsible vendors. For example, our print vendor, Lob,
            plants more trees than they use for our mailings, uses recycled pulp
            fiber, and offsets what they cannot yet avoid.
          </li>
        </ul>
      </div>
    </div>
  </section>

  <CallToActionCareers />
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToActionCareers from '@/components/CallToActionCareers.vue'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'

import { reactive, ref } from 'vue'
const { data } = defineProps(['data'])
const benefits = [
  'Medical insurance',
  'Company-Funded 401(k) Contribution',
  'Dental Insurance',
  'Unlimited PTO',
  'Vision Insurance',
  'Training & Continuing Professional Education',
  'Life Insurance',
  'Paid Community Volunteering Opportunities',
  'Short-Term Disability',
  'Long-Term Disability',
]
</script>
