<template>
  <div class="relative min-h-screen grid items-center overflow-hidden">
    <img
      src="@/assets/face-blue.svg"
      alt=""
      class="absolute -left-[100px] w-[970px] opacity-[0.05] z-10"
    />

    <section class="mx-auto max-w-7xl py-20 px-6 relative z-20">
      <div class="text-center">
        <h2
          class="text-[32px] sm:text-[48px] font-wide font-extrabold tracking-[2px] sm:max-w-[639px] mx-auto leading-tight mb-5 opacity-0 translate-y-8 uppercase max-w-[400px]"
          data-test="login.title"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                })
              }
            }
          "
        >
          Log in to your account
        </h2>
        <p
          class="max-w-[718px] mx-auto text-center text-neutral-600 opacity-0 translate-y-8"
          data-test="login.subtitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.1,
                  ease: 'expo',
                })
              }
            }
          "
        >
          Are you...
        </p>
      </div>

      <div
        class="max-w-5xl mx-auto grid sm:grid-cols-2 place-content-center justify-center gap-12 sm:gap-4 lg:gap-12 mt-20"
      >
        <div class="mx-auto">
          <a
            class="bg-[#D8EAFF] border-[#8EB6E4] border rounded-2xl p-8 md:aspect-[426/345] sm:max-w-[386px] relative overflow-hidden hover:bg-brand-blue transition cursor-pointer block group hover:scale-[1.03] hover:shadow-lg hover:shadow-brand-blue/50 ml-auto"
            :href="data.policy_holder.card_link.url"
          >
            <!-- document for bg -->
            <div
              class="overflow-hidden rounded-2xl"
              style="mix-blend-mode: overlay"
            >
              <svg
                width="185"
                height="210"
                viewBox="0 0 185 210"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute bottom-[-10px] right-[-10px] z-0"
              >
                <g opacity="0.33">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.7762 0.360059C15.2743 0.360059 9.99776 2.54569 6.10732 6.43613C2.21688 10.3266 0.03125 15.6031 0.03125 21.105V200.895C0.03125 206.397 2.21688 211.673 6.10732 215.564C9.99776 219.454 15.2743 221.64 20.7762 221.64H172.906C178.408 221.64 183.685 219.454 187.575 215.564C191.466 211.673 193.651 206.397 193.651 200.895V78.0984C193.646 72.5984 191.458 67.3254 187.566 63.4387L130.586 6.43143C128.657 4.50372 126.367 2.97525 123.847 1.93346C121.327 0.891669 118.626 0.357007 115.899 0.360059H20.7762ZM51.8937 117.915C49.1428 117.915 46.5045 119.008 44.5593 120.953C42.614 122.898 41.5212 125.537 41.5212 128.287C41.5212 131.038 42.614 133.677 44.5593 135.622C46.5045 137.567 49.1428 138.66 51.8937 138.66H141.789C144.54 138.66 147.178 137.567 149.123 135.622C151.068 133.677 152.161 131.038 152.161 128.287C152.161 125.537 151.068 122.898 149.123 120.953C147.178 119.008 144.54 117.915 141.789 117.915H51.8937ZM51.8937 159.405C49.1428 159.405 46.5045 160.498 44.5593 162.443C42.614 164.388 41.5212 167.027 41.5212 169.777C41.5212 172.528 42.614 175.167 44.5593 177.112C46.5045 179.057 49.1428 180.15 51.8937 180.15H141.789C144.54 180.15 147.178 179.057 149.123 177.112C151.068 175.167 152.161 172.528 152.161 169.777C152.161 167.027 151.068 164.388 149.123 162.443C147.178 160.498 144.54 159.405 141.789 159.405H51.8937Z"
                    fill="black"
                  />
                </g>
              </svg>
            </div>
            <!-- /document for bg -->
            <!-- circle -->
            <div class="relative z-10">
              <div
                class="p-4 rounded-full bg-[#CDE2FB] group-hover:bg-white transition inline-block w-[48px] h-[48px] md:w-[64px] md:h-[64px] grid place-content-center"
              >
                <svg
                  width="28"
                  height="32"
                  viewBox="0 0 28 32"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-[#0D4E9D] w-[18px] md:w-[28px] md:h-[32px]"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.47827 0.928408C2.72879 0.928408 2.01 1.22614 1.48004 1.7561C0.950074 2.28607 0.652344 3.00485 0.652344 3.75433V28.2457C0.652344 28.9952 0.950074 29.7139 1.48004 30.2439C2.01 30.7739 2.72879 31.0716 3.47827 31.0716H24.2017C24.9512 31.0716 25.67 30.7739 26.1999 30.2439C26.7299 29.7139 27.0276 28.9952 27.0276 28.2457V11.5181C27.027 10.7689 26.7288 10.0506 26.1987 9.5211L18.4368 1.75546C18.1741 1.49286 17.8621 1.28465 17.5188 1.14274C17.1755 1.00082 16.8076 0.927992 16.4361 0.928408H3.47827ZM7.71715 16.942C7.34241 16.942 6.98302 17.0908 6.71804 17.3558C6.45306 17.6208 6.30419 17.9802 6.30419 18.3549C6.30419 18.7297 6.45306 19.0891 6.71804 19.3541C6.98302 19.619 7.34241 19.7679 7.71715 19.7679H19.9628C20.3376 19.7679 20.697 19.619 20.9619 19.3541C21.2269 19.0891 21.3758 18.7297 21.3758 18.3549C21.3758 17.9802 21.2269 17.6208 20.9619 17.3558C20.697 17.0908 20.3376 16.942 19.9628 16.942H7.71715ZM7.71715 22.5938C7.34241 22.5938 6.98302 22.7427 6.71804 23.0077C6.45306 23.2727 6.30419 23.632 6.30419 24.0068C6.30419 24.3815 6.45306 24.7409 6.71804 25.0059C6.98302 25.2709 7.34241 25.4197 7.71715 25.4197H19.9628C20.3376 25.4197 20.697 25.2709 20.9619 25.0059C21.2269 24.7409 21.3758 24.3815 21.3758 24.0068C21.3758 23.632 21.2269 23.2727 20.9619 23.0077C20.697 22.7427 20.3376 22.5938 19.9628 22.5938H7.71715Z"
                  />
                </svg>
              </div>
            </div>
            <!-- /circle -->
            <h2
              class="uppercase text-brand-blue font-extrabold text-[18px] md:text-[24px] tracking-[2px] mt-6 group-hover:text-white transition relative z-10"
              data-test="login.policyholder"
            >
              A Policyholder
            </h2>
            <p
              class="text-brand-blue text-sm md:text-base font-wide group-hover:text-white transition relative z-10"
              data-test="login.policyholderDesc"
            >
              I have a policy with Neptune
            </p>
            <div
              class="mt-8 text-brand-blue font-semibold font-wide flex items-center space-x-4 group-hover:text-white transition"
              data-test="login.policyholderSelect"
            >
              <div>Select</div>
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                class="relative top-[-1.5px] group-hover:text-white transition text-brand-blue"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.335938 9.79282C0.335938 9.49522 0.448824 9.20981 0.649762 8.99937C0.850701 8.78894 1.12323 8.67072 1.4074 8.67072H16.6051L10.6649 2.74603C10.5587 2.64514 10.4729 2.52294 10.4126 2.38669C10.3523 2.25043 10.3187 2.10289 10.3139 1.95281C10.309 1.80272 10.333 1.65314 10.3844 1.51294C10.4358 1.37273 10.5135 1.24475 10.6129 1.13658C10.7124 1.02841 10.8316 0.942246 10.9634 0.883198C11.0953 0.824151 11.2371 0.793419 11.3805 0.792825C11.5239 0.792231 11.6659 0.821786 11.7982 0.879739C11.9305 0.937693 12.0504 1.02287 12.1506 1.13021L20.008 8.9849C20.1117 9.08958 20.1942 9.21507 20.2506 9.35388C20.3069 9.4927 20.3359 9.64198 20.3359 9.79282C20.3359 9.94365 20.3069 10.0929 20.2506 10.2317C20.1942 10.3706 20.1117 10.4961 20.008 10.6007L12.1506 18.4554C12.0504 18.5628 11.9305 18.6479 11.7982 18.7059C11.6659 18.7638 11.5239 18.7934 11.3805 18.7928C11.2371 18.7922 11.0953 18.7615 10.9634 18.7024C10.8316 18.6434 10.7124 18.5572 10.6129 18.4491C10.5135 18.3409 10.4358 18.2129 10.3844 18.0727C10.333 17.9325 10.309 17.7829 10.3139 17.6328C10.3187 17.4827 10.3523 17.3352 10.4126 17.1989C10.4729 17.0627 10.5587 16.9405 10.6649 16.8396L16.6051 10.9149H1.4074C1.12323 10.9149 0.850701 10.7967 0.649762 10.5863C0.448824 10.3758 0.335938 10.0904 0.335938 9.79282Z"
                />
              </svg>
            </div>
          </a>
          <div data-test="login.newQuote" v-html="data.policy_holder.sub_text"></div>
          <!--           <p class="text-sm text-center mt-3 text-brand-blue">
            Looking to quote a property?
            <a
              href="https://neptuneflood.com/consumer-app/"
              class="font-bold text-brand-blue underline"
              >New Quote</a
            >
          </p>
 -->
        </div>
        <div>
          <a
            class="bg-[#D8EAFF] border-[#8EB6E4] border rounded-2xl p-8 md:aspect-[426/345] sm:max-w-[386px]] relative overflow-hidden hover:bg-brand-blue transition cursor-pointer block group hover:scale-[1.03] hover:shadow-lg hover:shadow-brand-blue/50"
            :href="data.agent.card_link.url"
          >
            <!-- document for bg -->
            <div
              class="overflow-hidden rounded-2xl"
              style="mix-blend-mode: overlay"
            >
              <svg
                width="185"
                height="215"
                viewBox="0 0 185 215"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute bottom-[-10px] right-[-10px] z-0"
              >
                <path
                  opacity="0.33"
                  d="M96.4942 87.3971C108.063 87.3971 119.158 82.8014 127.339 74.6209C135.519 66.4404 140.115 55.3454 140.115 43.7764C140.115 32.2075 135.519 21.1124 127.339 12.932C119.158 4.75149 108.063 0.155754 96.4942 0.155754C84.9253 0.155754 73.8302 4.75149 65.6498 12.932C57.4693 21.1124 52.8736 32.2075 52.8736 43.7764C52.8736 55.3454 57.4693 66.4404 65.6498 74.6209C73.8302 82.8014 84.9253 87.3971 96.4942 87.3971ZM1.47386 181.807C0.124028 185.415 -0.0248985 189.362 1.04924 193.061C2.12339 196.76 4.36319 200.014 7.43536 202.338C32.9002 222.127 64.2437 232.848 96.4942 232.799C130.082 232.799 161.024 221.4 185.64 202.265C191.893 197.423 194.423 189.135 191.573 181.763C184.19 162.541 171.152 146.01 154.18 134.351C137.208 122.692 117.099 116.453 96.5083 116.457C75.9174 116.462 55.8118 122.71 38.8451 134.377C21.8784 146.044 8.84806 162.582 1.47386 181.807Z"
                  fill="black"
                />
              </svg>
            </div>
            <!-- /document for bg -->
            <!-- circle -->
            <div class="relative z-10">
              <div
                class="p-4 rounded-full bg-[#CDE2FB] group-hover:bg-white transition inline-block w-[48px] h-[48px] md:w-[64px] md:h-[64px] grid place-content-center"
              >
                <svg
                  width="24"
                  height="28"
                  viewBox="0 0 24 28"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-[#0D4E9D] h-[20px] md:w-[24px] md:h-[28px]"
                >
                  <path
                    d="M12.1341 10.6453C13.5024 10.6453 14.8146 10.1018 15.7821 9.13425C16.7496 8.16673 17.2932 6.8545 17.2932 5.48622C17.2932 4.11794 16.7496 2.8057 15.7821 1.83818C14.8146 0.870664 13.5024 0.327118 12.1341 0.327118C10.7658 0.327118 9.45356 0.870664 8.48604 1.83818C7.51853 2.8057 6.97498 4.11794 6.97498 5.48622C6.97498 6.8545 7.51853 8.16673 8.48604 9.13425C9.45356 10.1018 10.7658 10.6453 12.1341 10.6453ZM0.895841 21.8113C0.736194 22.238 0.71858 22.7049 0.845621 23.1424C0.972662 23.5799 1.23757 23.9647 1.60092 24.2395C4.61269 26.5801 8.31975 27.8481 12.1341 27.8423C16.1066 27.8423 19.7661 26.4941 22.6776 24.2309C23.417 23.6583 23.7163 22.6781 23.3792 21.8062C22.506 19.5328 20.964 17.5776 18.9567 16.1986C16.9493 14.8197 14.5711 14.0818 12.1357 14.0823C9.70041 14.0829 7.32249 14.8219 5.3158 16.2017C3.30912 17.5816 1.768 19.5375 0.895841 21.8113Z"
                    fill="#0D4E9D"
                  />
                </svg>
              </div>
            </div>
            <!-- /circle -->
            <h2
              class="uppercase text-brand-blue font-extrabold text-[18px] md:text-[24px] tracking-[2px] mt-6 group-hover:text-white transition"
              data-test="login.agent"
            >
              An agent
            </h2>
            <p
              class="text-brand-blue text-sm md:text-base font-wide group-hover:text-white transition"
              data-test="login.agentDesc"
            >
              I sell Neptune policies
            </p>
            <div
              class="mt-8 text-brand-blue font-semibold font-wide flex items-center space-x-4 group-hover:text-white transition"
              data-test="login.agentSelect"
            >
              <div>Select</div>

              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                class="relative top-[-1.5px] group-hover:text-white transition text-brand-blue"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.335938 9.79282C0.335938 9.49522 0.448824 9.20981 0.649762 8.99937C0.850701 8.78894 1.12323 8.67072 1.4074 8.67072H16.6051L10.6649 2.74603C10.5587 2.64514 10.4729 2.52294 10.4126 2.38669C10.3523 2.25043 10.3187 2.10289 10.3139 1.95281C10.309 1.80272 10.333 1.65314 10.3844 1.51294C10.4358 1.37273 10.5135 1.24475 10.6129 1.13658C10.7124 1.02841 10.8316 0.942246 10.9634 0.883198C11.0953 0.824151 11.2371 0.793419 11.3805 0.792825C11.5239 0.792231 11.6659 0.821786 11.7982 0.879739C11.9305 0.937693 12.0504 1.02287 12.1506 1.13021L20.008 8.9849C20.1117 9.08958 20.1942 9.21507 20.2506 9.35388C20.3069 9.4927 20.3359 9.64198 20.3359 9.79282C20.3359 9.94365 20.3069 10.0929 20.2506 10.2317C20.1942 10.3706 20.1117 10.4961 20.008 10.6007L12.1506 18.4554C12.0504 18.5628 11.9305 18.6479 11.7982 18.7059C11.6659 18.7638 11.5239 18.7934 11.3805 18.7928C11.2371 18.7922 11.0953 18.7615 10.9634 18.7024C10.8316 18.6434 10.7124 18.5572 10.6129 18.4491C10.5135 18.3409 10.4358 18.2129 10.3844 18.0727C10.333 17.9325 10.309 17.7829 10.3139 17.6328C10.3187 17.4827 10.3523 17.3352 10.4126 17.1989C10.4729 17.0627 10.5587 16.9405 10.6649 16.8396L16.6051 10.9149H1.4074C1.12323 10.9149 0.850701 10.7967 0.649762 10.5863C0.448824 10.3758 0.335938 10.0904 0.335938 9.79282Z"
                />
              </svg>
            </div>
          </a>
          <div data-test="login.newAgentEnrollment" v-html="data.agent.sub_text"></div>
          <!-- <p class="text-sm text-center mt-3 text-brand-blue">
            New to Neptune?
            <a
              href="https://neptuneflood.com/new-agent"
              class="font-bold text-brand-blue underline"
              >New Agent Enrollment</a
            >
          </p> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'
import LocomotiveScroll from 'locomotive-scroll'
const { data } = defineProps(['data'])

import { reactive, ref, onMounted } from 'vue'
const benefits = [
  'Medical insurance',
  '401(k) Match',
  'Dental Insurance',
  'Unlimited PTO',
  'Vision Insurance',
  'Training & Continuing Professional Education',
  'Life Insurance',
  'Paid Community Volunteering Opportunities',
  'Short-Term Disability',
  'Long-Term Disability',
]
</script>
