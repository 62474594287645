<template>
  <div
    class="bg-[#D8EAFF] border-[#8EB6E4] border rounded-2xl p-8 relative overflow-hidden block group ml-auto"
  >
    <!-- circle -->
    <div class="relative z-10">
      <div
        class="p-4 rounded-full bg-[#CDE2FB] transition inline-block w-[48px] h-[48px] md:w-[64px] md:h-[64px] grid place-content-center text-[#0D4E9D]"
      >
        <div class="w-[18px] w-[28px]" v-html="data.icon"></div>
      </div>
    </div>
    <!-- /circle -->
    <div
      class="uppercase text-brand-blue font-extrabold text-[18px] md:text-[20px] tracking-[2px] mt-6 transition relative z-10 leading-tight"
      v-html="data.headline"
    ></div>
    <p
      class="text-brand-blue text-sm md:text-base font-wide transition relative z-10 mt-2"
    >
      {{ data.body }}
    </p>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const { data } = defineProps(['data'])
</script>
