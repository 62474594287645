<template>
  <Dialog as="div" :open="open" @close="onClose" class="relative z-50">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
      >
        <DialogPanel
          class="mb-20 sm:mb-0 relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6 bg-white"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue"
              @click="onClose"
            >
              <span class="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="pt-8">
            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <DialogTitle
              as="h3"
              class="text-2xl leading-6 font-medium text-gray-900 mt-3 text-center sm:mt-5"
              data-test="onboard.success"
            >
              Success!
            </DialogTitle>
            <p class="mt-12 mb-4 text-center text-gray-700" data-test="onboard.verifyPostMsg">
              You should receive an email shortly with next steps. If you need
              any help please reach out to
              <a
                class="text-brand-blue underline"
                data-test="onboard.supportEmail"
                href="mailto:support@neptuneflood.com"
                >support@neptuneflood.com</a
              >
            </p>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";

const props = defineProps({
  open: { type: Boolean, default: false },
});

const emit = defineEmits(["close"]);

const onClose = () => {
  emit("close");
};
</script>
