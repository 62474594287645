<template>
  <div class="flex !opacity-[1]">
    <a
      class="bg-[#E0EAF3] border-[#A5BED5] border rounded-2xl p-8 w-full relative overflow-hidden transition cursor-pointer block ml-auto"
      :class="{ '!cursor-default': agent }"
      data-scroll-section
    >
      <!-- /document for bg -->
      <!-- circle -->
      <div class="relative z-10">
        <div
          class="p-4 rounded-full bg-[#CDE2FB] transition inline-block w-[48px] h-[48px] md:w-[64px] md:h-[64px] grid place-content-center"
        >
          <svg
            v-if="data.icon === 'Document'"
            viewBox="0 0 24 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5"
          >
            <path
              d="M4.45675 28.5779H19.5432C20.3435 28.5779 21.1109 28.26 21.6768 27.6941C22.2427 27.1283 22.5605 26.3608 22.5605 25.5606V11.0987C22.5605 10.6986 22.4015 10.3149 22.1185 10.032L13.9507 1.86421C13.6678 1.58127 13.2842 1.42227 12.8841 1.42218H4.45675C3.65651 1.42218 2.88905 1.74007 2.3232 2.30593C1.75735 2.87178 1.43945 3.63924 1.43945 4.43948V25.5606C1.43945 26.3608 1.75735 27.1283 2.3232 27.6941C2.88905 28.26 3.65651 28.5779 4.45675 28.5779Z"
              stroke="#004C9D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            v-if="data.icon === 'Dollar'"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-7"
          >
            <path
              d="M15.8418 9.0756C13.1492 9.0756 10.9668 10.4927 10.9668 12.2423C10.9668 13.9919 13.1492 15.4089 15.8418 15.4089C18.5344 15.4089 20.7168 16.826 20.7168 18.5756C20.7168 20.3252 18.5344 21.7423 15.8418 21.7423M15.8418 9.0756C17.6455 9.0756 19.2218 9.7121 20.0652 10.6589M15.8418 9.0756V7.49227M15.8418 9.0756V21.7423M15.8418 21.7423V23.3256M15.8418 21.7423C14.038 21.7423 12.4618 21.1058 11.6184 20.1589M30.4668 15.4089C30.4668 17.2803 30.0885 19.1333 29.3535 20.8622C28.6186 22.5911 27.5413 24.162 26.1832 25.4852C24.8252 26.8084 23.2129 27.8581 21.4385 28.5742C19.6642 29.2904 17.7624 29.6589 15.8418 29.6589C13.9212 29.6589 12.0194 29.2904 10.2451 28.5742C8.47066 27.8581 6.85842 26.8084 5.50036 25.4852C4.1423 24.162 3.06503 22.5911 2.33006 20.8622C1.59508 19.1333 1.2168 17.2803 1.2168 15.4089C1.2168 11.6296 2.75764 8.00506 5.50036 5.33266C8.24308 2.66027 11.963 1.15894 15.8418 1.15894C19.7206 1.15894 23.4405 2.66027 26.1832 5.33266C28.926 8.00506 30.4668 11.6296 30.4668 15.4089Z"
              stroke="#004C9D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-if="data.icon === 'Calendar'"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-7"
          >
            <path
              d="M7.32161 0.835938V4.37695M23.8464 0.835938V4.37695M1.41992 25.623V7.91797C1.41992 6.97883 1.79299 6.07816 2.45706 5.41409C3.12113 4.75002 4.0218 4.37695 4.96094 4.37695H26.207C27.1462 4.37695 28.0468 4.75002 28.7109 5.41409C29.375 6.07816 29.748 6.97883 29.748 7.91797V25.623M1.41992 25.623C1.41992 26.5622 1.79299 27.4629 2.45706 28.1269C3.12113 28.791 4.0218 29.1641 4.96094 29.1641H26.207C27.1462 29.1641 28.0468 28.791 28.7109 28.1269C29.375 27.4629 29.748 26.5622 29.748 25.623M1.41992 25.623V13.8197C1.41992 12.8805 1.79299 11.9799 2.45706 11.3158C3.12113 10.6517 4.0218 10.2786 4.96094 10.2786H26.207C27.1462 10.2786 28.0468 10.6517 28.7109 11.3158C29.375 11.9799 29.748 12.8805 29.748 13.8197V25.623M15.584 16.1803H15.5966V16.1929H15.584V16.1803ZM15.584 19.7214H15.5966V19.7339H15.584V19.7214ZM15.584 23.2624H15.5966V23.275H15.584V23.2624ZM12.043 19.7214H12.0556V19.7339H12.043V19.7214ZM12.043 23.2624H12.0556V23.275H12.043V23.2624ZM8.50195 19.7214H8.51454V19.7339H8.50195V19.7214ZM8.50195 23.2624H8.51454V23.275H8.50195V23.2624ZM19.125 16.1803H19.1376V16.1929H19.125V16.1803ZM19.125 19.7214H19.1376V19.7339H19.125V19.7214ZM19.125 23.2624H19.1376V23.275H19.125V23.2624ZM22.666 16.1803H22.6786V16.1929H22.666V16.1803ZM22.666 19.7214H22.6786V19.7339H22.666V19.7214Z"
              stroke="#004C9D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-if="data.icon === 'Clock'"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-7"
          >
            <path
              d="M16 9.73003V16L20.7025 20.7025M30.1074 16C30.1074 17.8526 29.7425 19.6871 29.0336 21.3987C28.3246 23.1103 27.2854 24.6655 25.9755 25.9755C24.6655 27.2854 23.1103 28.3246 21.3987 29.0336C19.6871 29.7425 17.8526 30.1074 16 30.1074C14.1474 30.1074 12.3129 29.7425 10.6013 29.0336C8.88973 28.3246 7.33454 27.2854 6.02455 25.9755C4.71455 24.6655 3.67541 23.1103 2.96644 21.3987C2.25748 19.6871 1.89258 17.8526 1.89258 16C1.89258 12.2585 3.37889 8.6702 6.02455 6.02455C8.6702 3.37889 12.2585 1.89258 16 1.89258C19.7415 1.89258 23.3298 3.37889 25.9755 6.02455C28.6211 8.6702 30.1074 12.2585 30.1074 16Z"
              stroke="#004C9D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            v-if="data.icon === 'Building'"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-7"
          >
            <path
              d="M26.88 30.5234V4.70399C26.88 3.84803 26.54 3.02712 25.9347 2.42185C25.3294 1.81659 24.5085 1.47656 23.6526 1.47656H7.51541C6.65944 1.47656 5.83853 1.81659 5.23327 2.42185C4.62801 3.02712 4.28798 3.84803 4.28798 4.70399V30.5234M26.88 30.5234H30.1074M26.88 30.5234H18.8114M4.28798 30.5234H1.06055M4.28798 30.5234H12.3566M18.8114 30.5234V22.4549C18.8114 22.0269 18.6414 21.6164 18.3388 21.3138C18.0361 21.0112 17.6257 20.8411 17.1977 20.8411H13.9703C13.5423 20.8411 13.1318 21.0112 12.8292 21.3138C12.5266 21.6164 12.3566 22.0269 12.3566 22.4549V30.5234M18.8114 30.5234H12.3566M10.7428 7.93142H12.3566M10.7428 14.3863H12.3566M18.8114 7.93142H20.4251M18.8114 14.3863H20.4251"
              stroke="#004C9D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <!-- /circle -->
      <h2
        class="uppercase text-brand-blue font-extrabold text-[18px] md:text-[20px] tracking-[2px] mt-6"
        data-test="benefitCard.headline"
      >
        {{ data.headline }}
      </h2>
      <p
        v-if="data.body"
        class="text-brand-blue text-sm md:text-lg font-wide"
        data-test="benefitCard.body"
      >
        {{ data.body }}
      </p>
      <div
        class="mt-2 text-brand-blue font-wide flex items-center space-x-4"
        data-test="benefitCard.text"
      >
        <div>{{ data.link?.text }}</div>
      </div>
    </a>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const { data, agent } = defineProps(['data', 'agent'])
</script>
