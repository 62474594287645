<template>
  <div>
    <div
      class="flex flex-col justify-column items-center min-h-screen bg-gray-50 rounded-lg border border-gray-300 p-6 md:p-12"
    >
      <div class="w-full">
        <Form
          :validation-schema="schema"
          v-slot="{ values, validate, setFieldValue }"
          :initial-values="initialValues"
          ref="formRef"
          @submit="onSubmit"
        >
          <div class="rounded-lg space-y-20">
            <div class="">
              <h2
                class="text-gray-500 mb-10 uppercase font-extrabold tracking-[2px]"
              >
                Get Started
              </h2>
              <div class="md:col-span-1 mb-10">
                <NeptuneFormHeader
                  headerText="Contact Information"
                  data-test="onboard.contactInfoHeader"
                />
              </div>
              <div class="flex flex-col">
                <div class="md:flex md:justify-between">
                  <div class="flex-col md:w-1/2 md:mr-4 mb-5">
                    <Field name="name" v-slot="{ handleChange, value, errors }">
                      <NeptuneInput
                        labelText="Full Name"
                        type="text"
                        placeholder="Enter name..."
                        data-test="onboard.name"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="name"
                      class="text-red-700 text-sm"
                      data-test="onboard.nameErr"
                    />
                  </div>
                  <div class="flex-col md:w-1/2 mb-5">
                    <Field
                      name="title"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Title"
                        type="text"
                        placeholder="Enter title..."
                        :includePopover="true"
                        popoverText="Job title or position"
                        :isTitle="true"
                        data-test="onboard.title"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>

                    <ErrorMessage
                      as="p"
                      name="title"
                      class="text-red-700 text-sm"
                      data-test="onboard.titleErr"
                    />
                  </div>
                </div>
                <div class="md:flex md:justify-between">
                  <div class="flex flex-col w-full md:mr-4 mb-5">
                    <Field
                      name="email"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Email"
                        type="email"
                        placeholder="Enter email..."
                        data-test="onboard.email"
                        :model-value="value"
                        @update:model-value="(val) => handleEmailChange(val, handleChange)"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="email"
                      class="text-red-700 text-sm"
                      data-test="onboard.emailErr"
                    />
                  </div>
                  <div class="flex flex-col w-full mb-5">
                    <Field
                      name="phone"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <neptune-tel-input
                        labelText="Phone Number"
                        :includePopover="true"
                        type="tel"
                        placeholder="Enter phone number..."
                        data-test="onboard.phone"
                        :model-value="value"
                        @update:model-value="(val) => handlePhoneChange(val, handleChange)"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="phone"
                      class="text-red-700 text-sm"
                      data-test="onboard.phoneErr"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="md:col-span-1 mb-10">
                <div>
                  <NeptuneFormHeader
                    headerText="Agency Details"
                    data-test="onboard.agencyDetailsHeader"
                  />
                </div>
              </div>
              <div class="flex flex-col">
                <div
                  class="md:flex justify-between"
                  v-if="grandkidSubs && grandkidSubs.length > 0"
                >
                  <div class="flex flex-col w-full mb-5">
                    <Field
                      name="selectedSubLink"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <neptune-subs-select
                        labelText="Select Subagency"
                        :arrOptions="grandkidSubs"
                        :model-value="value"
                        @update:model-value="handleChange"
                        @change="updateMarshSubsAddress(values)"
                        :errors="errors"
                      />
                    </Field>

                    <ErrorMessage
                      as="p"
                      name="selectedSubLink"
                      class="text-red-700 text-sm"
                    />
                  </div>
                </div>
                <div class="md:flex">
                  <div
                    class="flex-col md:w-1/2 md:mr-5 mb-5"
                    v-if="(requiresW9 && isSubAgent) || !isSubAgent"
                  >
                    <Field
                      name="irsName"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="IRS Name"
                        type="text"
                        placeholder="Enter IRS tax filing name..."
                        :includePopover="true"
                        popoverText="This is the legal name you file taxes under and matches what is registered under your EIN."
                        :isIRS="true"
                        data-test="onboard.irsName"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="irsName"
                      class="text-red-700 text-sm"
                      data-test="onboard.irsNameErr"
                    />
                  </div>

                  <div
                    class="flex-col md:w-1/2 mb-5"
                    v-if="(requiresW9 && isSubAgent) || !isSubAgent"
                  >
                    <Field
                      name="legalName"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Legal Name"
                        type="text"
                        placeholder="Enter legal name..."
                        data-test="onboard.legalName"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>

                    <ErrorMessage
                      as="p"
                      name="legalName"
                      class="text-red-700 text-sm"
                      data-test="onboard.legalNameErr"
                    />
                  </div>
                </div>
                <div class="md:flex">
                  <div class="flex-col w-full md:w-1/2 md:mr-4 mb-5">
                    <Field
                      name="agencyName"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Agency Name"
                        type="text"
                        placeholder="Enter agency name..."
                        :includePopover="true"
                        :popoverText="computedAgencyNamePopover"
                        data-test="onboard.agencyName"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      name="agencyName"
                      as="p"
                      class="text-red-700 text-sm"
                      data-test="onboard.agencyNameErr"
                    />
                  </div>

                  <div
                    class="flex-col w-full md:w-1/2 mb-5"
                    v-if="(requiresW9 && isSubAgent) || !isSubAgent"
                  >
                    <Field
                      name="billingEmail"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Billing Email"
                        type="text"
                        placeholder="Enter billing email..."
                        :includePopover="true"
                        popoverText="We pay commissions electronically using Bill.com. After binding your first policy, a Bill.com invite will be sent to this email. You must complete your Bill.com account from this email for you to receive commissions."
                        :isBilling="true"
                        data-test="onboard.billingEmail"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="billingEmail"
                      class="text-red-700 text-sm"
                      data-test="onboard.billingEmailErr"
                    />
                  </div>
                </div>
              </div>
              <div class="md:flex flex-col">
                <div class="md:flex">
                  <div class="flex flex-col w-full md:mr-5 mb-5">
                    <Field
                      name="streetAddress"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Street Address"
                        type="text"
                        placeholder="Enter street address..."
                        data-test="onboard.addr1"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="streetAddress"
                      class="text-red-700 text-sm"
                      data-test="onboard.addr1Err"
                    />
                  </div>
                  <div class="flex flex-col mb-5">
                    <Field
                      name="address2"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="Address 2"
                        type="text"
                        placeholder="Suite, floor, etc..."
                        data-test="onboard.addr2"
                        name="address2"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="address2"
                      class="text-red-700 text-sm"
                      data-test="onboard.addr2Err"
                    />
                  </div>
                </div>

                <div class="md:flex md:justify-around md:space-x-5">
                  <div class="flex flex-col flex-grow mb-5">
                    <Field name="city" v-slot="{ handleChange, value, errors }">
                      <NeptuneInput
                        labelText="City"
                        type="text"
                        placeholder="Enter city..."
                        data-test="onboard.city"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      name="city"
                      class="text-red-700 text-sm"
                      as="p"
                      data-test="onboard.cityErr"
                    />
                  </div>
                  <div class="flex flex-col flex-grow mb-5">
                    <Field
                      name="state"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <neptune-select
                        labelText="State"
                        data-test="onboard.state"
                        :arrOptions="dropdown"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      name="state"
                      class="text-red-700 text-sm"
                      data-test="onboard.stateErr"
                    />
                  </div>
                  <div class="flex flex-col flex-grow mb-5">
                    <Field
                      name="zipcode"
                      v-slot="{ handleChange, value, errors }"
                    >
                      <NeptuneInput
                        labelText="ZIP Code"
                        type="text"
                        placeholder="Enter zip code..."
                        v-mask="'#####-####'"
                        data-test="onboard.zip"
                        :model-value="value"
                        @update:model-value="handleChange"
                        :errors="errors"
                      />
                    </Field>
                    <ErrorMessage
                      name="zipcode"
                      as="p"
                      class="text-red-700 text-sm"
                      data-test="onboard.zipErr"
                    />
                  </div>
                </div>
                <div class="flex flex-col">
                  <div v-if="showEO" class="md:flex md:justify-around">
                    <div class="flex-col md:w-1/2 md:mr-4 mb-5">
                      <Field
                        name="eoInsurer"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <NeptuneInput
                          labelText="E&O Insurer"
                          type="text"
                          placeholder="Enter E&O Insurer..."
                          data-test="onboard.eoInsurer"
                          :model-value="value"
                          @update:model-value="handleChange"
                          :errors="errors"
                        />
                      </Field>

                      <ErrorMessage
                        as="p"
                        name="eoInsurer"
                        class="text-red-700 text-sm"
                        data-test="onboard.eoInsurerErr"
                      />
                    </div>
                    <div class="flex-col md:w-1/2 mb-5">
                      <Field
                        name="eoPolicyNumber"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <NeptuneInput
                          labelText="E&O Policy Number"
                          type="text"
                          placeholder="Enter E&O Policy Number..."
                          data-test="onboard.eoPolicy"
                          :model-value="value"
                          @update:model-value="handleChange"
                          :errors="errors"
                        />
                      </Field>

                      <ErrorMessage
                        as="p"
                        name="eoPolicyNumber"
                        class="text-red-700 text-sm"
                        data-test="onboard.eoPolicyErr"
                      />
                    </div>
                  </div>
                  <div v-if="showEO" class="md:flex md:justify-around">
                    <div class="flex flex-col w-full mr-4 mb-5">
                      <Field
                        name="eoLimit"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <neptune-currency-input
                          labelText="E&O Limit"
                          type="number"
                          placeholder="Enter E&O Limit..."
                          data-test="onboard.eoLimit"
                          :model-value="value"
                          @update:model-value="handleChange"
                          :errors="errors"
                        />
                      </Field>

                      <ErrorMessage
                        as="p"
                        name="eoLimit"
                        class="text-red-700 text-sm"
                        data-test="onboard.eoLimitErr"
                      />
                    </div>
                    <div class="flex flex-col w-full mb-5">
                      <Field
                        name="eoExpiration"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <NeptuneInput
                          type="date"
                          labelText="E&O Expiration Date"
                          data-test="onboard.eoExpiration"
                          :min="new Date().toISOString().split('T')[0]"
                          :model-value="value"
                          @update:model-value="
                            (...args) => {
                              handleChange(...args);
                            }
                          "
                          :errors="errors"
                        />
                      </Field>
                      <ErrorMessage
                        as="p"
                        name="eoExpiration"
                        class="text-red-700"
                        data-test="onboard.eoExpirationErr"
                      />
                    </div>
                  </div>
                  <div class="md:flex justify-between">
                    <div
                      class="flex flex-col w-full mr-4 mb-5"
                      v-if="(requiresW9 && isSubAgent) || !isSubAgent"
                    >
                      <Field
                        name="compType"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <neptune-select
                          labelText="Type"
                          data-test="onboard.type"
                          :arrOptions="selectData"
                          :model-value="value"
                          @update:model-value="handleChange"
                          :errors="errors"
                        />
                      </Field>

                      <ErrorMessage
                        as="p"
                        name="compType"
                        class="text-red-700"
                        data-test="onboard.typeErr"
                      />
                    </div>
                    <div
                      class="flex flex-col w-full mb-5"
                      v-if="(requiresW9 && isSubAgent) || !isSubAgent"
                    >
                      <Field
                        name="taxId"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <NeptuneInput
                          labelText="Tax ID"
                          v-mask="'#########'"
                          type="text"
                          placeholder="Enter tax ID..."
                          data-test="onboard.taxId"
                          :model-value="value"
                          @update:model-value="handleChange"
                          :errors="errors"
                        />
                      </Field>

                      <ErrorMessage
                        as="p"
                        name="taxId"
                        class="text-red-700 text-sm"
                        data-test="onboard.taxIdErr"
                      />
                    </div>
                    <div
                      class="flex flex-col w-full mb-5 ml-5"
                      :class="{ 'ml-0 w-1/2 pr-2': !requiresW9 }"
                      v-if="subsRequireRemoteReference"
                    >
                      <Field
                        name="remoteReferenceNo"
                        v-slot="{ handleChange, value, errors }"
                      >
                        <NeptuneInput
                          labelText="Remote Reference Number"
                          type="text"
                          :includePopover="true"
                          popoverText="Please enter your internal reference code in this field so we can map it to your Neptune code for commission."
                          :isReferenceNo="true"
                          placeholder="Enter remote reference number..."
                          :model-value="value"
                          @update:model-value="handleChange"
                          :errors="errors"
                        />
                      </Field>

                      <ErrorMessage
                        as="p"
                        name="remoteReferenceNo"
                        class="text-red-700 text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div class="md:col-span-1 mb-10">
                <NeptuneFormHeader
                  headerText="Mailing Address"
                  data-test="onboard.mailingAddHeader"
                />
              </div>
              <fieldset class="-mt-5 mb-5" v-if="showEO !== false">
                <div class="relative flex items-start mb-5">
                  <div class="flex items-center h-5">
                    <input
                      id="sameAddress"
                      aria-describedby="sameAddress"
                      name="sameAddress"
                      type="checkbox"
                      class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                      data-test="onboard.sameAddr"
                      v-model="sameAddress"
                      @change="() => updateMailingAddress(values, validate)"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="comments"
                      class="font-medium text-gray-700"
                      data-test="onboard.sameAddrLabel"
                      >Mailing address is the same as entered above
                    </label>
                  </div>
                </div>
              </fieldset>
              <div class="flex flex-col">
                <div class="md:flex">
                  <div class="flex flex-col w-full mr-5 mb-5">
                    <label
                      for="mailingAdd1"
                      class="block text-sm font-medium text-gray-700 mr-1"
                      >Mailing Street Address</label
                    >
                    <Field
                      name="mailingStreetAddress"
                      v-slot="{ field, errors }"
                      v-model="mailingStreetAddress"
                    >
                      <input
                        labelText="Street Address"
                        type="text"
                        placeholder="Enter street address..."
                        data-test="onboard.mailingAdd1"
                        name="mailingAdd1"
                        v-bind="field"
                        class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border border-gray-300 focus:ring-1 focus:ring-brand-blue text-jetblack-full"
                        :class="{ '!border-red-700': errors?.length > 0 }"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="mailingStreetAddress"
                      class="text-red-700 text-sm"
                      data-test="onboard.mailingAdd1Err"
                    />
                  </div>
                  <div class="flex flex-col mb-5">
                    <label
                      for="mailingAddress2"
                      class="block text-sm font-medium text-gray-700 mr-1"
                      >Mailing Address 2</label
                    >

                    <Field
                      name="mailingAddress2"
                      v-slot="{ field, errors }"
                      v-model="mailingAddress2"
                    >
                      <input
                        labelText="Address 2"
                        type="text"
                        placeholder="Suite, floor, etc..."
                        data-test="onboard.mailingAdd2"
                        name="mailingAddress2"
                        v-bind="field"
                        class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border border-gray-300 focus:ring-1 focus:ring-brand-blue text-jetblack-full"
                        :class="{ '!border-red-700': errors?.length > 0 }"
                      />
                    </Field>
                    <ErrorMessage
                      as="p"
                      name="mailingAddress2"
                      class="text-red-700 text-sm"
                      data-test="onboard.mailingAdd2Err"
                    />
                  </div>
                </div>

                <div class="md:flex justify-around">
                  <div class="flex flex-col flex-grow mb-5">
                    <label
                      for="mailingCity"
                      class="block text-sm font-medium text-gray-700 mr-1"
                      >Mailing City</label
                    >
                    <Field
                      name="mailingCity"
                      v-slot="{ field, errors }"
                      v-model="mailingCity"
                    >
                      <input
                        labelText="City"
                        type="text"
                        placeholder="Enter mailing city..."
                        data-test="onboard.mailingCity"
                        v-bind="field"
                        class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border border-gray-300 focus:ring-1 focus:ring-brand-blue text-jetblack-full"
                        :class="{ '!border-red-700': errors?.length > 0 }"
                      />
                    </Field>
                    <ErrorMessage
                      name="mailingCity"
                      class="text-red-700 text-sm"
                      as="p"
                      data-test="onboard.mailingCityErr"
                    />
                  </div>
                  <div class="flex flex-col flex-grow md:mx-5 mb-5">
                    <label
                      for="mailingState"
                      class="block text-sm font-medium text-gray-700 mr-1"
                      >Mailing State</label
                    >
                    <Field
                      name="mailingState"
                      v-slot="{ field, errors }"
                      v-model="mailingState"
                    >
                      <select
                        labelText="State"
                        data-test="onboard.mailingState"
                        :arrOptions="dropdown"
                        v-bind="field"
                        class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border border-gray-300 focus:ring-1 focus:ring-brand-blue text-jetblack-full"
                        :class="{ '!border-red-700': errors?.length > 0 }"
                      >
                        <option
                          :value="state.id"
                          v-for="state in stateDropdown"
                        >
                          {{ state.type }}
                        </option>
                      </select>
                    </Field>
                    <ErrorMessage
                      name="mailingState"
                      class="text-red-700 text-sm"
                      data-test="onboard.mailingStateErr"
                    />
                  </div>
                  <div class="flex flex-col flex-grow mb-5">
                    <label
                      for="mailingZip"
                      class="block text-sm font-medium text-gray-700 mr-1"
                      >Mailing Zip</label
                    >
                    <Field
                      name="mailingZipcode"
                      v-slot="{ field, errors }"
                      v-model="mailingZipcode"
                    >
                      <input
                        labelText="ZIP Code"
                        type="text"
                        placeholder="Enter zip code..."
                        data-test="onboard.mailingZip"
                        v-mask="'#####-####'"
                        v-bind="field"
                        class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border border-gray-300 focus:ring-1 focus:ring-brand-blue text-jetblack-full"
                        :class="{ '!border-red-700': errors?.length > 0 }"
                      />
                    </Field>
                    <ErrorMessage
                      name="mailingZipcode"
                      as="p"
                      class="text-red-700 text-sm"
                      data-test="onboard.mailingZipErr"
                    />
                  </div>
                </div>
              </div>
              <fieldset>
                <div class="relative flex items-start mb-5">
                  <div class="flex items-center h-5">
                    <input
                      id="isMailingAddressUserVerified"
                      aria-describedby="isMailingAddressUserVerified"
                      name="isMailingAddressUserVerified"
                      type="checkbox"
                      class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                      data-test="onboard.correctAddr"
                      v-model="isMailingAddressUserVerified"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="comments"
                      class="font-medium text-gray-700"
                      data-test="onboard.correctAddrLabel"
                      @click="
                        isMailingAddressUserVerified =
                          !isMailingAddressUserVerified
                      "
                      >I verify that the mailing address is correct
                    </label>
                  </div>
                </div>
                <p
                  v-if="
                    showAddressValidError &&
                    isMailingAddressUserVerified === false
                  "
                  class="mt-2 text-sm text-red-600"
                  id="isMailingAddressUserVerifiedError"
                >
                  Please confirm the mailing address is valid
                </p>
              </fieldset>
            </div>

            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <div
                v-if="errorMessage"
                class="text-red-700 text-sm inline-block mr-4"
              >
                {{ errorMessage }}
              </div>
              <button
                class="inline-flex justify-center py-3 px-7 border border-transparent shadow-sm text-sm font-bold rounded-full uppercase text-white bg-brand-blue hover:bg-brand-blue/75 focus:outline-none focus:ring-2 focus:ring-offset-2 tracking-[1px]"
                data-test="onboard.submit"
                type="submit"
              >
                <span v-if="loading">
                  <svg
                    class="h-5 w-5 mr-2 text-white animate-spin fill-brand-blue/50 inline-block"
                    viewBox="0 0 101 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  Loading...
                </span>
                <span v-else> Submit </span>
              </button>
            </div>
          </div>
          <VerificationModal
            :open="showVerificationModal"
            :phone="values.phone"
            @close="clearOrClose"
            @verified="(data) => onVerified(values, data, { setFieldValue })"
          />
          <SuccessModal :open="showSuccessModal" @close="onSuccessModalClose" />

          <div
            aria-live="assertive"
            class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
          >
            <div
              class="w-full flex flex-col items-center space-y-4 sm:items-end"
            >
              <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
              <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-if="showErrorToastForm"
                  class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden bg-white"
                  data-test="onboard.errorToast"
                >
                  <div class="p-4">
                    <div class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p
                          class="text-sm font-medium text-gray-900"
                          data-test="onboard.toastError"
                        >
                          {{ errorMessage }}
                        </p>
                      </div>
                      <div class="ml-4 flex-shrink-0 flex">
                        <button
                          type="button"
                          class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue"
                          data-test="onboard.toastClose"
                          @click="showErrorToastForm = false"
                        >
                          <span class="sr-only">Close</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <AppError :open="hasError" email="support@neptuneflood.com" />
</template>

<script setup>
import NeptuneFormHeader from "@/components/NeptuneFormHeader.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import NeptuneInput from "@/components/NeptuneInput.vue";
import NeptuneTelInput from "@/components/NeptuneTelInput.vue";
import NeptuneCurrencyInput from "@/components/NeptuneCurrencyInput.vue";
import Axios from "axios";
import NeptuneSelect from "@/components/NeptuneSelect.vue";
import NeptuneSubsSelect from "@/components/NeptuneSubsSelect.vue";
import typesArray from "@/utils/agencyTypes";
import AppError from "./AppError.vue";
import { createBaseSchema } from "@/schemas/baseSchema";
import { createW9Schema } from "@/schemas/w9SubAgentSchema";
import { createGrandkidSubSchema } from "@/schemas/grandkidSubSchema";
import { createEOSchema } from "@/schemas/EOSchema";
import stateDropdown from "@/utils/stateDropdown";
import VerificationModal from "./VerificationModal.vue";
import SuccessModal from "./SuccessModal.vue";

const props = defineProps(["baseUrl", "psdnUrl"]);

const mailingStreetAddress = ref("");
const mailingAddress2 = ref("");
const mailingCity = ref("");
const mailingZipcode = ref("");
const mailingState = ref("");

const store = useStore();
const token = computed(() => {
  return store.getters["auth/token"];
});

const isVerified = computed(() => {
  return store.getters["verification/isVerified"];
});

// computed ------------------------------
const requiresW9 = computed(() => {
  return store?.state?.subagency?.agencyData?.requiresW9;
});

const showEO = computed(() => {
  return store?.state?.subagency?.agencyData?.showEO !== false;
});

const subsRequireRemoteReference = computed(() => {
  return store?.state?.subagency?.agencyData?.subsRequireRemoteReference;
});

const isSubAgent = computed(() => {
  return store.getters["subagency/isSubAgent"];
});
const grandkidSubs = computed(() => {
  return store?.state?.subagency?.grandkidSubs;
});

const hasError = computed(() => {
  return !!store.getters["auth/error"];
});

const computedAgencyNamePopover = computed(() => {
  return showEO.value === false
    ? "This field is prefilled for your convenience. In the rare instance you need a different Agency Name displayed on client forms and documents, please update the field below (example - operating under a different legacy agency name) otherwise please leave this field as listed."
    : "This is the name you operate under publicly and may be different than your IRS Name, i.e. a 'doing business as' name. This is the name that will display on client forms and documents.";
});

// validation schema ------------------------------
const schema = computed(() => {
  let baseSchema = createBaseSchema(subsRequireRemoteReference);
  const w9Schema = createW9Schema(subsRequireRemoteReference, agencyTypeValues);
  const grandkidSubsSchema = createGrandkidSubSchema();
  const eoSchema = createEOSchema();
  // if subs exist add the required field
  if (store?.state?.subagency?.grandkidSubs)
    baseSchema = baseSchema.concat(grandkidSubsSchema);
  if (store?.state?.subagency?.agencyData?.showEO !== false)
    baseSchema = baseSchema.concat(eoSchema);

  if (
    (requiresW9.value === true && isSubAgent.value === true) ||
    isSubAgent.value === false
  ) {
    return baseSchema.concat(w9Schema);
  } else {
    return baseSchema;
  }
});

// vars ------------------------------
let showVerificationModal = ref(false);
let loading = ref(false);
let selectData = ref(typesArray);
let dropdown = ref(stateDropdown);
let showErrorToastForm = ref(false);
const agencyTypeValues = typesArray.map((x) => x.id);
let sameAddress = ref(false);
let errorMessage = ref("");
let isMailingAddressUserVerified = ref(false);
let showAddressValidError = ref(false);
let formRef = ref(null);

const showSuccessModal = ref(false);
const onSuccessModalClose = () => {
  formRef.value.resetForm();
  isMailingAddressUserVerified.value = false;
  sameAddress.value = false;
  showSuccessModal.value = false;
};

const initialValues = ref({
  eoExpiration: new Date().toISOString().split("T")[0],
  email: "",
  agencyName:
    store?.state?.subagency?.agencyData?.showEO === false
      ? "Marsh & McLennan Agency LLC"
      : "",
  streetAddress: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  phone: "",
  name: "",
  title: "",
  compType: 1,
  taxId: "",
  eoInsurer: "",
  eoPolicyNumber: "",
  eoLimit: 0,
  verificationMethod: "sms",
  verificationCode: "",
  mailingStreetAddress: "",
  mailingAddress2: "",
  mailingState: "",
  mailingCity: "",
  mailingZipcode: "",
  remoteReferenceNo: "",
  selectedSubLink: "",
});
// methods ------------------------------
const handlePhoneChange = (val, handleChangeCallback) => {
  // clear the error message if it has anything to do with phone
  if (errorMessage.value?.toLowerCase().includes("phone")) {
    errorMessage.value = null;
  }

  handleChangeCallback(val);
};

const handleEmailChange = (val, handleChangeCallback) => {
  // clear the error message if it has anything to do with email
  if (errorMessage.value?.toLowerCase().includes("email")) {
    errorMessage.value = null;
  }

  handleChangeCallback(val);
};

const updateMarshSubsAddress = (values) => {
  let selectedSubAddress = grandkidSubs.value.filter(
    (x) => x.autoRegistrationLink === values.selectedSubLink
  );
  values.streetAddress = selectedSubAddress[0].addr1;
  values.city = selectedSubAddress[0].city;
  values.state = selectedSubAddress[0].state;
  values.zipcode = selectedSubAddress[0].zip;
  mailingStreetAddress.value = selectedSubAddress[0].addr1;
  mailingCity.value = selectedSubAddress[0].city;
  mailingState.value = selectedSubAddress[0].state;
  mailingZipcode.value = selectedSubAddress[0].zip;
};
const clearOrClose = () => {
  if (isVerified.value) {
    formRef.value.resetForm();
    isMailingAddressUserVerified.value = false;
    sameAddress.value = false;
  }

  showVerificationModal.value = false;
};

const updateMailingAddress = async (values, validate) => {
  if (sameAddress.value === true) {
    mailingStreetAddress.value = values.streetAddress;
    mailingAddress2.value = values.address2;
    mailingCity.value = values.city;
    mailingZipcode.value = values.zipcode;
    mailingState.value = values.state;
  } else {
    mailingStreetAddress.value = "";
    mailingAddress2.value = "";
    mailingCity.value = "";
    mailingZipcode.value = "";
    mailingState.value = "";
  }

  await validate(values);
};

const onVerified = (values, data, actions) => {
  showVerificationModal.value = false;
  let phone = data.phone;
  if (phone !== values.phone) {
    actions.setFieldValue("phone", data.phone);
  }

  onSubmit({
    ...values,
    phone,
  });
};

const onSubmit = async (values) => {
  try {
    loading.value = true;
    errorMessage.value = null;

    await schema.value.validate(values);
    if (isMailingAddressUserVerified.value === false) {
      showAddressValidError.value = true;
      return;
    }

    if (!isVerified.value) {
      showVerificationModal.value = true;
      return;
    }

    const response = await createAgency(values);
    const agencyId = response.data?.payload?.agencyInfo?.agencyId;
    if (!agencyId) {
      throw new Error(
        "There was an issue creating your agency. Please try again. Contact support if the issue persists."
      );
    }

    showSuccessModal.value = true;
  } catch (e) {
    if (e.response) {
      const {
        data: { payload },
      } = e.response;

      if (payload.errors.length > 0) {
        errorMessage.value = payload.errors[0].message;
      } else if (payload.data.candidates.length > 0) {
        errorMessage.value = payload.data.candidates[0].reason;
      }
    } else {
      errorMessage.value =
        e.message ??
        "There was an unexpected error. Please try again or contact support.";
      showErrorToastForm.value = true;
    }
  } finally {
    loading.value = false;
  }
};

const buildPayload = (values) => {
  // Helper function to determine parentLink value
  function getParentLink() {
    if (isSubAgent.value === true) {
      if (values.selectedSubLink !== "") {
        return values.selectedSubLink;
      }
      const parts = window.location.hash.split("/sub-agent/");

      return decodeURIComponent(parts[1]);
    }
    return null;
  }

  const payload = {
    parentLink: getParentLink(),
    email: values.email,
    company: values.agencyName,
    addr1: values.streetAddress,
    addr2: values.address2,
    city: values.city,
    state: values.state,
    zip: values.zipcode,
    phone: values.phone,
    name: values.name,
    title: values.title,
    eoInsurer: values.eoInsurer,
    eoPolicyNumber: values.eoPolicyNumber,
    eoLimit: parseInt(values.eoLimit),
    eoExpiration: new Date(values.eoExpiration).toJSON(),
    mailingAddr1: mailingStreetAddress.value,
    mailingAddr2: mailingAddress2.value,
    mailingCity: mailingCity.value,
    mailingState: mailingState.value,
    mailingZip: mailingZipcode.value,
    isMailingAddressUserVerified: isMailingAddressUserVerified.value,
    remoteReferenceNo: values.remoteReferenceNo,
    autoRegistrationLink: values.selectedSubLink,
  };
  if (showEO.value === false) {
    payload.eoInsurer =
      store.state.subagency.agencyDetailsObj.agencyDetailInfo.eoInsurer;
    payload.eoPolicyNumber =
      store.state.subagency.agencyDetailsObj.agencyDetailInfo.eoPolicyNumber;
    payload.eoLimit = parseInt(
      store.state.subagency.agencyDetailsObj.agencyDetailInfo.eoLimit
    );
    payload.eoExpiration = new Date(
      store.state.subagency.agencyDetailsObj.agencyDetailInfo.eoExpiration
    ).toJSON();
  }

  // Conditionally add the taxId field if needed
  if (
    (requiresW9.value === true && isSubAgent.value === true) ||
    isSubAgent.value === false
  ) {
    payload.taxId = values.taxId;
    payload.irsName = values.irsName;
    payload.legalname = values.legalName;
    payload.billingEmail = values.billingEmail;
    payload.type = values.compType;
  }

  return payload;
};

const createAgency = async (values) => {
  const payload = buildPayload(values);

  return await Axios.post(
    `${props.psdnUrl}/api/v1/agenciesauto/upsert`,
    payload,
    {
      headers: { Authorization: token.value },
    }
  );
};
</script>

<style>
/* Change the background color when the checkbox is checked */
input[type="checkbox"]:checked {
  color: #004c9d !important; /* Custom color */
  border-color: #004c9d;
}
</style>
