<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.05]"
  />

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.default_faqs?.headline || 'Frequently asked questions' }}
    </h2>
    <FAQs
      :data="{
        faqs: data?.default_faqs?.questions_and_answers || generalFaqs,
      }"
    />
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.flood_insurance_101_faqs?.headline || 'Flood Insurance 101' }}
    </h2>
    <FAQs
      :data="{
        faqs:
          data?.flood_insurance_101_faqs?.questions_and_answers ||
          floodInsurance101FAQs,
      }"
    />
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{
        data?.buying_flood_insurance_faqs?.headline ||
        'Buying Flood Insurance FAQs'
      }}
    </h2>
    <FAQs
      :data="{
        faqs:
          data?.buying_flood_insurance_faqs?.questions_and_answers ||
          buyingFloodInsuranceFAQs,
      }"
    />
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.residential_faqs?.headline || 'Residential FAQs' }}
    </h2>
    <FAQs
      :data="{
        faqs: data?.residential_faqs?.questions_and_answers || residentialFaqs,
      }"
    />
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.commercial_faqs?.headline || 'Commercial FAQs' }}
    </h2>
    <FAQs
      :data="{
        faqs: data?.commercial_faqs?.questions_and_answers || commercialFaqs,
      }"
    />
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.excess_faqs?.headline || 'Excess FAQs' }}
    </h2>
    <FAQs
      :data="{
        faqs: data?.excess_faqs?.questions_and_answers || excessFaqs,
      }"
    />
  </section>

  <CallToAction :baseUrl="data.baseUrl" />
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToAction from '@/components/CallToAction.vue'
import FAQs from '@/components/FAQs.vue'
import {
  generalFaqs,
  floodInsurance101FAQs,
  buyingFloodInsuranceFAQs,
  residentialFaqs,
  excessFaqs,
  commercialFaqs,
} from '@/data/faqs.js'
const { data } = defineProps(['data'])
</script>
