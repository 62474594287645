<template>
  <div
    class="prose mt-12 !max-w-none opacity-0 translate-y-8"
    v-intersection-observer="
      ([{ isIntersecting, target }]) => {
        if (isIntersecting) {
          gsap.to(target, {
            duration: 1.0,
            opacity: 1,
            delay: 0.25,
            y: 0,
            ease: 'expo',
          })
        }
      }
    "
  >
    <table class="">
      <thead>
        <tr>
          <th class="w-[31%] py-6 text-left">RCBAP Comparison</th>
          <th class="w-[37.5%] text-center pb-0">
            <span
              class="bg-brand-blue block px-8 py-6 rounded-t-3xl shadow-2xl shadow-brand-blue"
            >
              <span class="relative top-[4px]">
                <span
                  class="block text-[24px] font-extrabold tracking-[1px] leading-none text-white"
                  >NEPTUNE</span
                >
                <span class="block font-normal text-white">RCBAP Flood</span>
              </span>
            </span>
          </th>
          <th class="w-1/3 text-center px-8 py-6 text-[#53575A]">
            <span class="leading-none text-base">NFIP</span>
            <span class="block font-normal leading-none">RCBAP Flood</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in rcbapRows">
          <td>{{ row[0] }}</td>
          <td class="text-center py-0">
            <span
              class="bg-brand-blue text-white block px-8 py-6 shadow-2xl shadow-brand-blue"
              :class="{ 'rounded-b-3xl': i === rcbapRows?.length - 1 }"
            >
              {{ row[1] }}
            </span>
          </td>
          <td class="text-center px-8 py-6 text-[#53575A]">{{ row[2] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
const rcbapRows = [
  [
    'Building Coverage',
    '$15,000,000 per building',
    '$250,000 per unit/per building',
  ],
  ['Contents Coverage', '$500,000', '$100,000'],
  ['Additional Coverage', 'Basement Contents, Pool Repair & Refill', 'N/A'],

  ['Elevation Certificate', 'Optional', 'No'],
  [
    'Waiting Period',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 10 days.',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 30 days.',
  ],
]
</script>
