<template>
  <div
    class="p-8 border border-gray-[#D6D6D6] border text-left prose rounded-xl"
  >
    <div v-html="data.icon"></div>
    <h2 class="mt-6 uppercase font-bold">{{ data.headline }}</h2>
    <div v-html="data.body"></div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const { data } = defineProps(['data'])
</script>
