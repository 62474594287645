import './style.scss'
import gsap from 'gsap'
import { ObserveVisibility } from 'vue-observe-visibility'
import { createApp } from 'vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import HomeCanvas from '@/components/HomeCanvas.vue'
import Team from '@/pages/Team.vue'
import Careers from '@/pages/Careers.vue'
import Login from '@/pages/Login.vue'
import Support from '@/pages/Support.vue'
import Technology from '@/pages/Technology.vue'
import NewAgent from '@/pages/NewAgent.vue'
import Home from '@/pages/Home.vue'
import store from './store'
import { mask } from 'vue-the-mask'
import NeptuneInput from '@/components/NeptuneInput.vue'
import Faqs from '@/pages/FAQs.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import ResidentialInsurance from '@/pages/ResidentialInsurance.vue'
import CommercialFloodInsurance from '@/pages/CommercialFloodInsurance.vue'
import ExcessFloodInsurance from '@/pages/ExcessFloodInsurance.vue'
import Claims from '@/pages/Claims.vue'
import Torar from '@/pages/Torar.vue'
import RcbapInsurance from '@/pages/RcbapInsurance.vue'
import Blog from '@/pages/Blog.vue'
import Newsroom from '@/pages/Newsroom.vue'
import TermsOfService from '@/pages/TermsOfService.vue'
import MarketingAssets from '@/pages/MarketingAssets.vue'
import AllFaqs from '@/pages/AllFaqs.vue'

const app = createApp({
  components: {
    SiteHeader,
    SiteFooter,
    Team,
    Careers,
    Login,
    Support,
    Technology,
    Faqs,
    ResidentialInsurance,
    CommercialFloodInsurance,
    ExcessFloodInsurance,
    NewAgent,
    Home,
    HomeCanvas,
    Claims,
    Torar,
    RcbapInsurance,
    Blog,
    Newsroom,
    TermsOfService,
    MarketingAssets,
    AllFaqs,
  },

  data() {
    return {
      contactButton: 1,
    }
  },
  mounted() {
    gsap.to('#loader', {
      y: '-100%',
      duration: 0.4,
      ease: 'expo.in',
      delay: 0.2,
    })

    document.addEventListener('DOMContentLoaded', () => {
      // Define a breakpoint (e.g., 768 pixels for tablets)
      const mobileBreakpoint = 768

      document.body.addEventListener('click', (event) => {
        const target = event.target

        if (
          target.tagName === 'A' &&
          target.href.includes(window.location.hostname)
        ) {
          event.preventDefault()
          console.log('GOGOGOG')

          // Execute your GSAP animation
          gsap.set('#loader', {
            y: '100%',
          })
          gsap.to('#loader', {
            y: 0,
            duration: 0.3,
            ease: 'expo',
            onComplete: () => {
              window.location.href = target.href
            },
          })
        }
      })
    })
  },
})

const routes = []
app.use(store)
app.directive('mask', mask)
app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)
app.component('neptune-input', NeptuneInput)
app.mount('#app')
