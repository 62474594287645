import Axios from "axios";

// initial state
const state = () => ({
  token: null,
});

const getters = {
  token(state) {
    return state.token;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  async getToken({ commit }) {
    try {
      const source = window.btoa(
        `${process.env.VUE_APP_ONBOARDING_SOURCE}:${process.env.VUE_APP_ONBOARDING_KEY}`
      );

      const res = await Axios.get(`${process.env.VUE_APP_TOKEN_URL}`, {
        headers: {
          Authorization: `Basic ${source}`,
        },
      });

      commit("setToken", res.data);
    } catch (e) {
      console.log(e);
      commit("setError", e);
    }
  },
};

// mutations
const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
