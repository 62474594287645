<template>
  <section
    class="bg-black w-full mx-auto max-w-7xl p-8 md:p-12 lg:p-20 rounded-2xl min-h-[425px] flex flex-col justify-center relative overflow-hidden"
    data-scroll-section
  >
    <img
      src="@/assets/face-white.svg"
      alt="Neptune Face"
      class="absolute top-[10px] left-[-100px] opacity-15"
      data-scroll
      data-scroll-speed="0.08"
    />
    <h1
      class="text-white max-w-[725px] md:mx-auto md:text-center text-[24px] lg:text-[32px] font-wide font-extrabold tracking-[2px] uppercase opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.2,
              ease: 'expo',
            });
          }
        }
      "
    >
      Ready to join forces?
    </h1>
    <p
      class="text-white md:text-center max-w-[631px] mx-auto mt-3 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.3,
              ease: 'expo',
            });
          }
        }
      "
    >
      View and apply for our open positions on indeed.com. If you’re interested
      in a role you don’t see listed, we’re always willing to hear from talented
      individuals. Send your resume to careers@neptuneflood.com and we’ll reach
      out if we have an opening for someone like you.
    </p>
    <div
      class="opacity-0 translate-y-8 md:text-center mt-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.35,
              ease: 'expo',
            });
          }
        }
      "
    >
      <a
        href="https://www.indeed.com/cmp/Neptune-Flood/jobs"
        class="bg-white text-black uppercase tracking-[1px] text-sm font-semibold py-3 md:py-4 px-6 md:px-8 rounded-full inline-block hover:bg-white/75 transition text-center"
        target="_blank"
      >
        View open positions
      </a>
    </div>
  </section>
</template>

<script setup>
import gsap from "gsap";
import { vIntersectionObserver } from "@vueuse/components";
import LocomotiveScroll from "locomotive-scroll";
import { onMounted } from "vue";
onMounted(() => {
  const scroll = new LocomotiveScroll();
  console.log(scroll);
});
</script>
