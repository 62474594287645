<template>
  <div
    class="fixed inset-0 z-50 flex items-start md:items-center justify-center px-4 mt-4 md:mt-0 overflow-scroll md:overflow-auto py-10"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75"
      @click="$emit('close')"
    ></div>

    <div
      class="bg-white p-6 md:p-12 relative rounded-2xl inline-flex z-10 mx-auto mt-8 md:m-auto mb-24 max-w-screen-xl w-full"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <div
        class="grid md:grid-cols-12 space-y-8 md:space-y-0 md:space-x-[60px]"
      >
        <div class="relative md:col-span-6 lg:col-span-4">
          <div
            class="absolute inset-x-0 top-1/2 -z-10 transform-gpu overflow-hidden blur-3xl -translate-y-1/2"
            aria-hidden="true"
          >
            <div
              class="relative aspect-[1155/678] -translate-x-1/2 bg-gradient-to-tr from-brand-green to-brand-green opacity-40 left-1/2 w-[400px] h-[500px] md:w-[600px] md:h-[600px] md:-ml-16 md:-mt-16"
              style="
                clip-path: polygon(
                  74.1% 44.1%,
                  100% 61.6%,
                  97.5% 26.9%,
                  85.5% 0.1%,
                  80.7% 2%,
                  72.5% 32.5%,
                  60.2% 62.4%,
                  52.4% 68.1%,
                  47.5% 58.3%,
                  45.2% 34.5%,
                  27.5% 76.7%,
                  0.1% 64.9%,
                  17.9% 100%,
                  27.6% 76.8%,
                  76.1% 97.7%,
                  74.1% 44.1%
                );
              "
            />
          </div>
          <img
            v-if="data.image"
            :src="data.image.url"
            :alt="data.image.alt"
            class="aspect-[370/490] object-cover max-w-[200px] md:max-w-[438px] h-full w-full rounded-2xl"
          />
        </div>

        <div
          class="md:col-span-6 lg:col-span-8 overflow-scroll md:overflow-auto"
        >
          <h1
            class="text-base uppercase font-bold text-neutral-950 font-extrabold font-wide tracking-[1px"
          >
            {{ data.name }}
          </h1>
          <h2 class="text-sm text-neutral-600">
            {{ data.title }}
          </h2>
          <div
            v-html="data.description"
            class="prose mt-8 overflow-scroll md:overflow-auto w-full prose-sm prose-p:text-neutral-600"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, nextTick, ref, defineEmits } from 'vue'

let { data } = defineProps(['data'])
const emit = defineEmits(['close'])

onMounted(() => {
  document.body.classList.add('overflow-hidden', 'relative')
  document.querySelector('html').classList.add('overflow-hidden')
  window.addEventListener('keydown', closeModalWithKey)
})

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden', 'relative')
  document.querySelector('html').classList.remove('overflow-hidden')
  window.removeEventListener('keydown', closeModalWithKey)
})

const closeModalWithKey = (e) => {
  if (e.keyCode === 27) {
    window.removeEventListener('keydown', closeModalWithKey)
    emit('close')
  }
}
</script>
