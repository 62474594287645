<template>
  <div :class="[containerClass]">
    <div class="flex">
      <label
        :for="filteredAttrs['name']"
        class="block text-sm font-medium text-gray-700 mr-1"
        >{{ labelText }}</label
      >
      <div
        v-if="includePopover"
        class="group relative block text-brand-blue underline hover:text-brand-teal duration-300 cursor-pointer -mt-1"
        @click="popoverOpened = !popoverOpened"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <!-- Tooltip text here -->
        <span
          class="absolute hidden group-hover:lg:flex -top-6 -right-3 translate-x-full w-64 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm before:content-[''] before:absolute before:top-1/2 before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-700 z-10"
          >Use a phone number capable of receiving a call or SMS message, for
          verification purposes.</span
        >
      </div>

      <!-- mobile tooltip -->
      <Teleport to="body">
        <transition
          mode="in-out"
          enter-from-class="opacity-0 scale-90"
          enter-active-class="transition ease-out duration-300"
          enter-to-class="opacity-100 scale-100"
          leave-from-class="opacity-100 scale-100"
          leave-active-class="transition ease-in duration-200"
          leave-to-class="opacity-0 scale-90"
        >
          <div
            v-if="popoverOpened"
            class="fixed top-auto z-[20000] p-4 bottom-0 inset-x-0 w-full"
          >
            <div
              class="p-4 bg-black/75 rounded-lg text-white text-sm relative"
              v-on-click-outside="
                () => {
                  popoverOpened = false
                }
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-5 h-5 text-white right-0 top-0 absolute m-2 z-10 cursor-pointer"
                @click="
                  () => {
                    popoverOpened = false
                  }
                "
              >
                <path
                  d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                />
              </svg>

              <p class="opacity-75 mb-1">{{ labelText }}</p>

              Use a phone number capable of receiving a call or SMS message, for
              verification purposes.
            </div>
          </div>
        </transition>
      </Teleport>
      <!-- /mobile tooltip -->
    </div>

    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        v-bind="filteredAttrs"
        @input="onInput"
        :value="modelValue"
        v-mask="'###-###-####'"
        class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md border border-gray-300"
        :class="{ '!border-red-700': props?.errors?.length > 0 }"
      />
    </div>
  </div>
</template>

<script setup>
import { useAttrs, ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
const { class: containerClass, ...filteredAttrs } = useAttrs()
const popoverOpened = ref(false)

const props = defineProps({
  labelText: String,
  modelValue: [String, Number, Date],
  errors: Array,
  includePopover: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const onInput = (event) => {
  emit('update:modelValue', event.target.value)
}
</script>
<script>
export default {
  inheritAttrs: false,
}
</script>
