<template>
  <Dialog as="div" :open="open" @close="onClose" class="relative z-50">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
      >
        <DialogPanel
          class="mb-20 sm:mb-0 relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6 bg-white"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue"
              @click="onClose"
            >
              <span class="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <DialogTitle
              as="h3"
              class="text-lg leading-6 font-medium text-gray-900 text-center"
              data-test="onboard.verifyTitle"
            >
              Phone Verification
            </DialogTitle>
            <p
              class="text-sm text-gray-500 mb-5 text-center max-w-[28em] mx-auto"
              data-test="onboard.verifySubTitle"
            >
              One more step! Please select how you would like to verify your
              phone information ({{ phoneField }}).
            </p>
            <div v-if="!isCodeSent">
              <div class="my-8 space-y-4">
                <div v-if="error">
                  <neptune-tel-input
                    labelText="Phone Number"
                    v-model="phoneField"
                    type="tel"
                    placeholder="Enter phone number..."
                    data-test="onboard.phone"
                    v-bind="phoneAttrs"
                    :error="errors.phone"
                  />
                  <div class="min-h-5">
                    <ErrorMessage
                      as="p"
                      name="phone"
                      class="text-red-500 text-sm"
                      data-test="verify.phoneErr"
                    />
                  </div>
                </div>
                <neptune-select
                  class="mb-4 text-left"
                  labelText="How would you like to verify your phone number?"
                  data-test="onboard.verifyMethod"
                  v-model="method"
                  :arrOptions="[
                    { type: 'SMS', id: 'sms' },
                    { type: 'Call', id: 'call' },
                  ]"
                  v-bind="methodAttrs"
                />
                <span>
                  <ErrorMessage
                    class="text-left"
                    name="verificationMethod"
                    data-test="onboard.verifyMethodErr"
                  />
                </span>
              </div>
              <p v-if="error" class="text-red-500 text-left mb-3">
                {{ error }}
              </p>
              <button
                type="button"
                class="mb-3 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-brand-blue hover:bg-primaryLight focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                data-test="onboard.sendButton"
                @click="onSendCode"
                :class="{
                  'bg-neutral hover:bg-neutralLight': isCodeSent,
                }"
              >
                <span v-if="isLoading">
                  <svg
                    class="h-5 w-5 mr-2 text-white animate-spin fill-brand-blue/50 inline-block"
                    viewBox="0 0 101 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  Loading...
                </span>
                <span v-else>
                  {{ isCodeSent ? "Resend Code" : "Send Code" }}
                </span>
              </button>
            </div>
            <div v-else>
              <NeptuneInput
                class="my-8"
                labelText="Enter verification code"
                type="text"
                placeholder="Enter code..."
                data-test="onboard.verifyCode"
                v-model="code"
                :error="errors.verificationCode"
                v-bind="codeAttrs"
              />
              <p v-if="error" class="text-red-500 text-left mb-3">
                {{ error }}
              </p>
              <button
                type="button"
                data-test="onboard.verifyButton"
                @click="onVerify"
                class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-blue text-base font-medium text-white hover:bg-primaryLight focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
              >
                <span v-if="isLoading">
                  <svg
                    class="h-5 w-5 mr-2 text-white animate-spin fill-brand-blue/50 inline-block"
                    viewBox="0 0 101 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  Loading...
                </span>
                <span v-else>Verify and Create</span>
              </button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import { useForm } from "vee-validate";
import { object, string } from "yup";
import NeptuneInput from "@/components/NeptuneInput.vue";
import NeptuneSelect from "@/components/NeptuneSelect.vue";
import NeptuneTelInput from "@/components/NeptuneTelInput.vue";
const PhoneRegex = /\d{3}-\d{3}-\d{4}/;

const props = defineProps({
  open: { type: Boolean, default: false },
  phone: { type: String, default: "" },
});

const emit = defineEmits(["close", "verified"]);

const store = useStore();

const isLoading = computed(() => {
  return store.getters["verification/isLoading"];
});

const isCodeSent = computed(() => {
  return store.getters["verification/isCodeSent"];
});

const error = computed(() => {
  return store.getters["verification/error"];
});

const validationSchema = object({
  phone: string().required().matches(PhoneRegex, "Invalid phone number"),
  verificationMethod: string().required().oneOf(["sms", "call"]),
  verificationCode: string().required(),
});

const { errors, defineField, setFieldValue } = useForm({
  validationSchema,
  initialValues: {
    phone: props.phone,
    verificationMethod: "sms",
    verificationCode: "",
  },
});

const [phoneField, phoneAttrs] = defineField("phone");
const [method, methodAttrs] = defineField("verificationMethod");
const [code, codeAttrs] = defineField("verificationCode");

watch(
  () => props.phone,
  (newVal) => {
    if (newVal && newVal !== phoneField.value) {
      setFieldValue("phone", newVal);
      setFieldValue("verificationCode", "");
      store.commit("verification/setPhone", newVal);
    }
  }
);

const onClose = () => {
  setFieldValue("verificationCode", "");
  emit("close");
};

const onSendCode = async (ev) => {
  store.commit("verification/setError", null);

  if (phoneField.value !== store.state.verification.phone) {
    store.commit("verification/setPhone", phoneField.value);
  }

  await store.dispatch("verification/sendCode", {
    method: method.value,
  });
};

const onVerify = async (ev) => {
  store.commit("verification/setError", null);

  await store.dispatch("verification/verifyCode", {
    code: code.value,
  });

  emit("verified", { phone: phoneField.value });
};
</script>
