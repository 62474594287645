import * as yup from "yup";

const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const createW9Schema = (subsRequireRemoteReference, agencyTypeValues) => {
  return yup.object({
    compType: yup.number().oneOf(agencyTypeValues),
    taxId: yup
      .string()
      .required("Tax ID is required")
      .test("len", "Tax ID must be 9 digits", (val) => val?.length === 9),
    billingEmail: yup
      .string()
      .required("Billing Email is a required field")
      .matches(EmailRegex, "Please enter a valid email"),
    legalName: yup.string().required("Please enter your legal name"),
    irsName: yup
      .string()
      .max(40, "Max length of 40 characters is exceeded")
      .required("Please enter the name used on your IRS tax filing")
  });
};
