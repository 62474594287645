<template>
  <div class="flex">
    <div
      class="bg-[#D8EAFF] border-[#8EB6E4] border rounded-2xl p-8 w-full relative overflow-hidden ml-auto"
    >
      <!-- icon  -->
      <div v-if="!data.noIcon" class="relative z-10 mb-6">
        <div v-if="data.icon" v-html="data.icon"></div>
        <svg
          v-else
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2969 9.49577C13.6043 9.49577 11.4219 10.9129 11.4219 12.6624C11.4219 14.412 13.6043 15.8291 16.2969 15.8291C18.9895 15.8291 21.1719 17.2462 21.1719 18.9958C21.1719 20.7454 18.9895 22.1624 16.2969 22.1624M16.2969 9.49577C18.1006 9.49577 19.6769 10.1323 20.5203 11.0791M16.2969 9.49577V7.91243M16.2969 9.49577V22.1624M16.2969 22.1624V23.7458M16.2969 22.1624C14.4931 22.1624 12.9169 21.5259 12.0735 20.5791M30.9219 15.8291C30.9219 17.7004 30.5436 19.5535 29.8086 21.2823C29.0736 23.0112 27.9964 24.5821 26.6383 25.9054C25.2803 27.2286 23.668 28.2783 21.8936 28.9944C20.1192 29.7105 18.2175 30.0791 16.2969 30.0791C14.3763 30.0791 12.4745 29.7105 10.7001 28.9944C8.92574 28.2783 7.31349 27.2286 5.95544 25.9054C4.59738 24.5821 3.52011 23.0112 2.78514 21.2823C2.05016 19.5535 1.67187 17.7004 1.67188 15.8291C1.67188 12.0498 3.21272 8.42522 5.95544 5.75283C8.69816 3.08044 12.4181 1.5791 16.2969 1.5791C20.1757 1.5791 23.8956 3.08044 26.6383 5.75283C29.381 8.42522 30.9219 12.0498 30.9219 15.8291Z"
            stroke="#004C9D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <!-- /icon  -->
      <h2
        class="uppercase text-brand-blue font-extrabold text-sm tracking-[1px] max-w-[300px]"
      >
        {{ data.headline }}
      </h2>
      <div
        v-if="data.body"
        class="text-brand-blue text-sm md:text-base font-wide transition mt-3 prose prose-strong:text-brand-blue prose-li:marker:text-brand-blue prose-th:text-brand-blue prose-th:text-left prose-a:text-brand-blue"
        v-html="data.body"
      ></div>

      <a
        v-if="data.link?.text"
        :href="data.link.url"
        class="mt-4 text-brand-blue font-semibold font-wide flex items-center space-x-2 transition text-sm group hover:text-brand-blue/75"
        :class="{
          'group-hover:text-white': data.link?.url,
        }"
      >
        <div>{{ data.link?.text }}</div>
        <svg
          viewBox="0 0 21 19"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          class="relative top-[-1.5px] transition w-4 group-hover:translate-x-2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.335938 9.79282C0.335938 9.49522 0.448824 9.20981 0.649762 8.99937C0.850701 8.78894 1.12323 8.67072 1.4074 8.67072H16.6051L10.6649 2.74603C10.5587 2.64514 10.4729 2.52294 10.4126 2.38669C10.3523 2.25043 10.3187 2.10289 10.3139 1.95281C10.309 1.80272 10.333 1.65314 10.3844 1.51294C10.4358 1.37273 10.5135 1.24475 10.6129 1.13658C10.7124 1.02841 10.8316 0.942246 10.9634 0.883198C11.0953 0.824151 11.2371 0.793419 11.3805 0.792825C11.5239 0.792231 11.6659 0.821786 11.7982 0.879739C11.9305 0.937693 12.0504 1.02287 12.1506 1.13021L20.008 8.9849C20.1117 9.08958 20.1942 9.21507 20.2506 9.35388C20.3069 9.4927 20.3359 9.64198 20.3359 9.79282C20.3359 9.94365 20.3069 10.0929 20.2506 10.2317C20.1942 10.3706 20.1117 10.4961 20.008 10.6007L12.1506 18.4554C12.0504 18.5628 11.9305 18.6479 11.7982 18.7059C11.6659 18.7638 11.5239 18.7934 11.3805 18.7928C11.2371 18.7922 11.0953 18.7615 10.9634 18.7024C10.8316 18.6434 10.7124 18.5572 10.6129 18.4491C10.5135 18.3409 10.4358 18.2129 10.3844 18.0727C10.333 17.9325 10.309 17.7829 10.3139 17.6328C10.3187 17.4827 10.3523 17.3352 10.4126 17.1989C10.4729 17.0627 10.5587 16.9405 10.6649 16.8396L16.6051 10.9149H1.4074C1.12323 10.9149 0.850701 10.7967 0.649762 10.5863C0.448824 10.3758 0.335938 10.0904 0.335938 9.79282Z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const { data } = defineProps(['data'])
</script>
