import * as yup from "yup";

export const createEOSchema = () => {
  return yup.object({
    eoInsurer: yup.string().required("E&O Insurer is required"),
    eoPolicyNumber: yup.string().required("E&O Policy Number is required"),
    eoLimit: yup
      .string()
      .required("E&O Limit is required")
      .test(
        "len",
        "E&O Limit must be at least $1",
        (val) => parseInt(val) >= 1
      ),
    eoExpiration: yup
      .date()
      .required("Please enter a valid E&O Expiration date")
      .nullable()
      .typeError("Invalid Date")
      .default(() => new Date()),
  });
};
