<template>
  <div class="mx-auto absolute top-[-100px] min-h-[600px] md:min-h-[964px]">
    <div class="relative">
      <div
        class="bg-gradient-to-t from-white to-transparent inset-x-0 h-[150px] md:h-[300px] bottom-0 absolute z-10"
      ></div>
      <canvas ref="myCanvas" class="relative"></canvas>
    </div>
  </div>
</template>

<script setup>
import * as THREE from 'three'
import { onMounted, ref } from 'vue'
const myCanvas = ref(null)
onMounted(() => {
  let scene, camera, renderer, plane

  init()
  animate()

  function init() {
    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / 964, 0.1, 1000)
    camera.position.z = 1

    renderer = new THREE.WebGLRenderer({ canvas: myCanvas.value })
    renderer.setSize(window.innerWidth, 964)
    scene.background = new THREE.Color(0xffffff)

    const geometry = new THREE.PlaneGeometry(2, 2) // Full viewport plane

    const uniforms = {
      iTime: { value: 0 },
      iResolution: { value: new THREE.Vector3() },
    }

    const material = new THREE.ShaderMaterial({
      uniforms: {
        iTime: { value: 0 },
        iResolution: {
          value: new THREE.Vector3(window.innerWidth, 964, 1),
        },
      },
      vertexShader: `
    void main() {
      gl_Position = vec4(position, 1.0);
    }
  `,
      fragmentShader: `
    uniform float iTime;
    uniform vec3 iResolution;

    vec2 center = vec2(0.5, 0.5);
    float speed = 0.035;

    void mainImage(out vec4 fragColor, in vec2 fragCoord) {
      float invAr = iResolution.y / iResolution.x;
      vec2 uv = fragCoord.xy / iResolution.xy;

      // Ripple color, adjusted to a more noticeable color if desired
      vec3 rippleColor = vec3(0.9068627451, 0.9264705882, 0.95); // Light blue for the ripple

      vec3 texcol;

      float x = (center.x - uv.x);
      float y = (center.y - uv.y) * invAr;
      float r = -(x * x + y * y); // Using circle equation for symmetric ripples
      float z = 1.0 + 0.5 * sin((r + iTime * speed) / 0.013);

      texcol.x = z;
      texcol.y = z;
      texcol.z = z;

      // Blend the ripple color with a near-white background
      vec3 backgroundColor = vec3(1.0, 1.0, 1.0); // A near-white color, adjust as needed
      vec3 finalColor = mix(backgroundColor, rippleColor, smoothstep(0.0, 1.0, z));

      fragColor = vec4(finalColor, 1.0);
    }

    void main() {
      mainImage(gl_FragColor, gl_FragCoord.xy);
    }
  `,
    })

    uniforms.iResolution.value.x = window.innerWidth
    uniforms.iResolution.value.y = 964

    plane = new THREE.Mesh(geometry, material)
    scene.add(plane)

    window.addEventListener('resize', onWindowResize, false)
  }

  function animate() {
    requestAnimationFrame(animate)

    plane.material.uniforms.iTime.value += 0.01

    renderer.render(scene, camera)
  }

  function onWindowResize() {
    // Update camera aspect ratio and renderer size
    camera.aspect = window.innerWidth / 964
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, 964)

    // Update shader resolution uniform
    plane.material.uniforms.iResolution.value.x = window.innerWidth
    plane.material.uniforms.iResolution.value.y = 964
  }

  // const canvas = document.querySelector('canvas')
  // const c = canvas.getContext('2d')
  // canvas.width = innerWidth
  // canvas.he964

  // function renderBaseCircle() {
  //   c.shadowBlur = 20
  //   c.shadowColor = 'white'

  //   c.beginPath()
  //   c.arc(canvas.width / 2, -100, 550, 0, Math.PI * 2, false)
  //   c.fillStyle = '#F3F6FA'
  //   c.fill()
  // }

  // class Ring {
  //   constructor() {
  //     this.radius = 550
  //     this.lineWidth = 35
  //     this.opacity = 1
  //   }

  //   draw() {
  //     c.save()
  //     // c.globalAlpha = this.opacity
  //     c.shadowBlur = 30
  //     c.shadowColor = 'red'
  //     c.beginPath()
  //     c.arc(canvas.width / 2, -100, this.radius, 0, Math.PI * 2, false)
  //     c.lineWidth = 1
  //     c.strokeStyle = '#F3F6FA'
  //     c.stroke()
  //     c.closePath()
  //     c.restore()
  //   }

  //   update() {
  //     this.draw()
  //     this.radius += 0.1
  //     this.lineWidth -= 0.01
  //     // if (this.opacity > 0.001) this.opacity -= 0.001
  //     // else this.opacity = 0
  //     console.log(this.opacity)
  //   }
  // }

  // const ring = new Ring()

  // function animate() {
  //   requestAnimationFrame(animate)
  //   c.clearRect(0, 0, canvas.width, canvas.height)
  //   renderBaseCircle()
  //   ring.update()
  // }
  // animate()
})
</script>
