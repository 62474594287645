import Axios from "axios";

// initial state
const state = () => ({
  phone: "",
  sent: false,
  verified: false,
  loading: false,
  error: null,
});

const getters = {
  isCodeSent(state) {
    return state.sent;
  },
  isLoading(state) {
    return state.loading;
  },
  isVerified(state) {
    return state.verified;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  async sendCode({ commit, state, rootGetters }, { method }) {
    if (!["sms", "call"].includes(method)) {
      commit("setError", `Invalid method '${method} provided'`);
      commit("setCodeSent", false);
      return;
    }

    try {
      commit("setLoading", true);
      const phone = state.phone.replace(/-/g, "");
      const token = rootGetters["auth/token"];

      const response = await Axios.post(
        `${phpVars.psdnUrl}/api/v1/phone/verifyv2`,
        {
          phone,
          channel: `${method}`,
          serviceName: "Neptune",
        },
        { headers: { Authorization: token } }
      );

      const successful = response.data?.payload?.successful;
      const errors = response.data?.payload?.errors;
      if (!successful) {
        if (errors?.length > 0) {
          let [message] = errors;
          if (message?.toLowerCase().includes("invalid parameter")) {
            message =
              "The phone number you provided appears to be invalid. Please double-check the number and try again.";
          } else if (message?.toLowerCase().includes("max")) {
            message =
              "You have reached the maximum number of attempts. Please try again later or contact our support team.";
          }

          commit("setError", message);
        } else {
          commit("setError", "An unknown error occurred. Please try again.");
        }

        commit("setCodeSent", false);
        return;
      }

      commit("setCodeSent", true);
    } catch (e) {
      console.error(e);
      commit("setCodeSent", false);
      commit("setError", e);
    } finally {
      commit("setLoading", false);
    }
  },
  async verifyCode({ commit, state, rootGetters }, { code }) {
    if (!code) {
      commit("setError", "No code provided");
      return;
    }

    try {
      commit("setLoading", true);
      const phone = state.phone.replace(/-/g, "");
      const token = rootGetters["auth/token"];

      const response = await Axios.put(
        `${phpVars.psdnUrl}/api/v1/phone/verify`,
        {
          phone,
          code,
        },
        { headers: { Authorization: token } }
      );

      const successful = response.data?.payload?.successful;
      const errors = response.data?.payload?.errors;
      if (!successful) {
        // Default error message
        let message = "There was an error with your code, please try again";

        if (errors?.length > 0) {
          const [firstError] = errors;
          if (firstError.includes("The requested resource")) {
            message =
              "Please enter a valid email address and ensure the form has correct values";
          } else if (firstError !== "Invalid parameter: Code") {
            message = firstError;
          }
        }

        commit("setVerified", false);
        commit("setError", message);
        return;
      }

      commit("setError", null);
      commit("setVerified", true);
    } catch (e) {
      console.error(e);
      commit("setVerified", false);
      commit("setError", e);
    } finally {
      commit("setLoading", false);
    }
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPhone(state, phone) {
    if (state.phone !== phone) {
      state.error = null;
      state.sent = false;
      state.verified = false;
    }

    state.phone = phone;
  },
  setCodeSent(state, isCodeSent) {
    state.sent = isCodeSent;
  },
  setVerified(state, verified) {
    state.verified = verified;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
