<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.10] z-10 mix-blend-difference pointer-events-none"
  />

  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div class="absolute inset-0 bg-brand-blue rounded-b-[50px]"></div>
    <div class="text-center relative z-10">
      <h1
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[420px] md:max-w-[789px] leading-tight mb-5 opacity-0 translate-y-8 uppercase text-white mx-auto text-white"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.4,
              })
            }
          }
        "
      >
        Blog
      </h1>
      <div
        class="mx-auto max-w-[482px] w-full relative opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.5,
              })
            }
          }
        "
      >
        <p class="text-lg text-white max-w-sm mx-auto">
          Tips and insights on being prepared and protecting your property.
        </p>
      </div>
    </div>
  </section>
  <div class="bg-white py-12">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-4xl">
        <div class="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
          <article
            v-for="post in data.posts"
            :key="post.id"
            class="relative isolate flex flex-col gap-8 lg:flex-row"
          >
            <div
              class="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0"
            >
              <a :href="post.link">
                <img
                  :src="post.imageUrl"
                  alt=""
                  class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                />
              </a>
              <div
                class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10 pointer-events-none"
              />
            </div>
            <div>
              <div class="flex items-center gap-x-4 text-xs">
                <time :datetime="post.datetime" class="text-gray-500">{{
                  post.date
                }}</time>
                <a
                  :href="post.category.href"
                  class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-extrabold text-gray-600 hover:bg-gray-100"
                  >{{ post.category.title }}</a
                >
              </div>
              <div class="group relative max-w-xl">
                <h3
                  class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 uppercase"
                >
                  <a :href="post.link">
                    <span class="absolute inset-0" />
                    {{ post.title }}
                  </a>
                </h3>
                <p class="mt-5 text-sm leading-6 text-gray-600">
                  {{ post.excerpt }}
                </p>
              </div>
              <!-- <div class="mt-6 flex border-t border-gray-900/5 pt-6">
                <div class="relative flex items-center gap-x-4">
                  <img
                    :src="post.author.imageUrl"
                    alt=""
                    class="h-10 w-10 rounded-full bg-gray-50"
                  />
                  <div class="text-sm leading-6">
                    <p class="font-semibold text-gray-900">
                      <a :href="post.author.href">
                        <span class="absolute inset-0" />
                        {{ post.author.name }}
                      </a>
                    </p>
                    <p class="text-gray-600">{{ post.author.role }}</p>
                  </div>
                </div>
              </div> -->
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
  <CallToAction class="mt-24" :baseUrl="data.baseUrl" />
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import { reactive, ref } from 'vue'
import CallToAction from '@/components/CallToAction.vue'
const { data } = defineProps(['data'])
</script>
