<template>
  <Transition
    :css="false"
    mode="out-in"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<script>
import gsap from 'gsap'

export default {
  props: {
    scale: { type: Number, default: 0.83 },
    leaveEase: { type: String, default: 'power3.in' },
  },
  methods: {
    beforeEnter(el) {
      gsap.set(el.querySelector('.bg-white'), {
        opacity: 1,
        scale: 0,
      })
      gsap.set(el.querySelector('.bg-opacity-75'), {
        opacity: 0,
      })
    },
    enter(el, done) {
      gsap.to(el.querySelector('.bg-white'), {
        duration: 0.5,
        scale: 1,
        ease: 'expo',
        onComplete: done,
      })
      gsap.to(el.querySelector('.bg-opacity-75'), {
        duration: 0.5,
        opacity: 1,
        ease: 'expo',
        onComplete: done,
      })
    },
    leave(el, done) {
      gsap.to(el.querySelector('.bg-white'), {
        duration: 0.3,
        opacity: 0,
        ease: 'expo',
        onComplete: done,
      })
      gsap.to(el.querySelector('.bg-opacity-75'), {
        duration: 0.4,
        opacity: 0,
        ease: 'expo',
        onComplete: done,
      })
    },
  },
}
</script>
