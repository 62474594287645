const stateDropdown = [
  //   { id: "AA", type: "AA" },
  //   { id: "AE", type: "AE" },
  { id: "AL", type: "Alabama" },
  { id: "AK", type: "Alaska" },
  //   { id: "AP", type: "AP" },
  { id: "AZ", type: "Arizona" },
  { id: "AR", type: "Arkansas" },
  { id: "CA", type: "California" },
  { id: "CO", type: "Colorado" },
  { id: "CT", type: "Connecticut" },
  { id: "DE", type: "Delaware" },
  { id: "FL", type: "Florida" },
  { id: "GA", type: "Georgia" },
  { id: "HI", type: "Hawaii" },
  { id: "ID", type: "Idaho" },
  { id: "IL", type: "Illinois" },
  { id: "IN", type: "Indiana" },
  { id: "IA", type: "Iowa" },
  { id: "KS", type: "Kansas" },
  { id: "KY", type: "Kentucky" },
  { id: "LA", type: "Louisiana" },
  { id: "ME", type: "Maine" },
  { id: "MD", type: "Maryland" },
  { id: "MA", type: "Massachusetts" },
  { id: "MI", type: "Michigan" },
  { id: "MN", type: "Minnesota" },
  { id: "MS", type: "Mississippi" },
  { id: "MO", type: "Missouri" },
  { id: "MT", type: "Montana" },
  { id: "NE", type: "Nebraska" },
  { id: "NV", type: "Nevada" },
  { id: "NH", type: "New Hampshire" },
  { id: "NJ", type: "New Jersey" },
  { id: "NM", type: "New Mexico" },
  { id: "NY", type: "New York" },
  { id: "NC", type: "North Carolina" },
  { id: "ND", type: "North Dakota" },
  { id: "OH", type: "Ohio" },
  { id: "OK", type: "Oklahoma" },
  { id: "OR", type: "Oregon" },
  { id: "PA", type: "Pennsylvania" },
  { id: "PR", type: "Puerto Rico" },
  { id: "RI", type: "Rhode Island" },
  { id: "SC", type: "South Carolina" },
  { id: "SD", type: "South Dakota" },
  { id: "TN", type: "Tennessee" },
  { id: "TX", type: "Texas" },
  { id: "UT", type: "Utah" },
  { id: "VT", type: "Vermont" },
  { id: "VA", type: "Virginia" },
  { id: "WA", type: "Washington" },
  { id: "WV", type: "West Virginia" },
  { id: "WI", type: "Wisconsin" },
  { id: "WY", type: "Wyoming" },
  { id: "DC", type: "District of Columbia" }
];
export default stateDropdown;
