<template>
  <div :class="['shadow-sm', attrClass]">
    <label
      :for="filteredAttrs['name']"
      class="block text-sm font-medium text-gray-700"
      >{{ labelText }}</label
    >
    <select
      id="location"
      name="location"
      v-bind="filteredAttrs"
      @input="onInput"
      :value="modelValue"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-blue focus:border-brand-blue sm:text-sm rounded-md"
      :class="{ '!border-red-700': props?.errors?.length > 0 }"
    >
      <option
        v-for="item in arrOptions"
        :key="item.id"
        :value="item.id"
        :selected="item.id === 1"
      >
        {{ item.type }}
      </option>
    </select>
  </div>
</template>
<script setup>
import { useAttrs } from 'vue'
const { class: attrClass, ...filteredAttrs } = useAttrs()

const props = defineProps({
  labelText: String,
  modelValue: [String, Number, Date],
  arrOptions: Array,
  errors: Array,
})

const emit = defineEmits(['update:modelValue'])

const onInput = (event) => {
  emit('update:modelValue', event.target.value)
}
</script>
<script>
export default {
  inheritAttrs: false,
};
</script>
