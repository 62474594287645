<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.10] z-10 mix-blend-difference"
  />

  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div
      class="absolute inset-0 bg-brand-blue rounded-b-[50px] scale-y-0 origin-top"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.4,
              opacity: 1,
              scaleY: 1,
              ease: 'expo',
            })
          }
        }
      "
    ></div>
    <div class="text-center">
      <h1
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[420px] md:max-w-[789px] leading-snug mb-5 opacity-0 translate-y-8 uppercase text-white mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        A New Era for Insurance Technology
      </h1>
      <p
        class="max-w-[350px] sm:max-w-[718px] mx-auto text-white opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        Harnessing the power of AI and ML to protect your home and business.
      </p>
    </div>
  </section>
  <section class="prose mx-auto my-24 px-6">
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      Buying insurance shouldn’t require an advanced degree. You want to know if
      you’re at risk, and you want to pay a fair price to protect your family,
      your home, and your business. That’s where Neptune’s advanced technology
      comes in.
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              delay: 0.1,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      Our Model as a Service (MaaS) platform uses artificial intelligence
      enhanced by machine learning (ML). We utilize pinpoint geospatial analysis
      and cutting-edge storm surge and inland flood modeling, allowing you to
      understand the price to protect your home or business in seconds.
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Neptune’s engines then connect your policy to a selection of the world’s
      strongest insurance markets, so you can sleep at night knowing we’ve got
      you covered!
    </p>
  </section>

  <section class="mb-20">
    <div
      class="max-w-7xl mx-auto rounded-3xl bg-brand-air text-center p-8 md:px-16 pt-12 relative z-10"
    >
      <h2
        class="text-[24px] md:text-[32px] uppercase font-bold max-w-[500px] mx-auto mb-4 leading-tight opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      >
        EXPERIENCE TRITON TECHNOLOGY
      </h2>
      <p
        class="text-neutral-600 opacity-0 translate-y-8 max-w-3xl mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      >
        See Triton technology at work in real time. In just a few seconds,
        Triton analyzes the property address, prices the risk, and places it
        with one of our global carrier partners. No human involvement means
        you're getting fair pricing based on your property's risk.
      </p>
      <iframe
        :src="`${data.baseUrl}/visualizer`"
        frameborder="0"
        class="w-full aspect-video mt-8 rounded-3xl opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      ></iframe>
      <div
        class="text-center p-2 mt-4 opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      >
        <a href="/visualizer" class="underline font-bold" target="_blank"
          >View Full Screen</a
        >
      </div>
    </div>
  </section>

  <section class="prose mx-auto my-24 px-6 relative">
    <svg
      viewBox="0 0 150 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-[440px] text-brand-blue opacity-0 translate-y-8 absolute right-[115%] top-[-100px] mix-blend-overlay opacity-[0.05]"
    >
      <path
        d="M149.984 265.562H0.574219L28.4282 115.967H7.06145V100.311H28.7848L28.1033 89.6886H7.06145V74.0326H27.0965L25.4396 48.2145H9.99116V32.5585H24.8889L25.2953 31.6646C33.7193 13.0066 52.9913 0.948242 74.3898 0.948242C95.7883 0.948242 115.056 13.0062 123.48 31.6646L123.886 32.5585H146.594V48.2145H124.175V74.0326H143.669V89.6886H124.175V100.311H143.669V115.967H124.961L149.984 265.562ZM49.899 249.906H131.493L120.351 183.305L49.899 249.906ZM19.4084 249.906H27.1148L117.241 164.71L111.052 127.696L27.4488 206.725L19.4084 249.906ZM44.3509 115.967L32.3153 180.582L100.673 115.967H44.3509ZM83.1932 100.311H108.519V89.6886H83.1932V100.311ZM44.4683 100.311H67.5372V89.6886H43.7867L44.4683 100.311ZM42.7843 74.0326H108.519V48.2145H41.1274L42.7843 74.0326ZM42.7123 32.5585H106.063C99.188 22.7034 87.3647 16.6046 74.3902 16.6046C61.4157 16.6046 49.5876 22.7034 42.7123 32.5585Z"
        fill="currentColor"
      />
    </svg>

    <h2
      class="text-[24px] md:text-[32px] font-wide font-extrabold tracking-[2px] max-w-[589px] leading-snug mb-5 opacity-0 translate-y-8 uppercase mt-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      WE’RE
      <span class="relative bg-white">
        <span
          class="absolute bg-black inset-x-[-7px] bottom-[-3px] top-[-10px] rounded-md scale-x-0 origin-left"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 2.5,
                  scaleX: 1,
                  ease: 'expo',
                  delay: 0.3,
                })
              }
            }
          "
        ></span>
        <span>
          <span class="mix-blend-difference relative z-10 text-white"
            >Different,</span
          >
        </span>
      </span>

      AND WE’LL PROVE IT
    </h2>

    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Technology is developing fast, and Neptune is here to ensure you can take
      advantage.
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Our engines focus on data. That’s it. We don’t need your income, employer,
      or social security number. You simply tell us your address, and our
      technology does the rest.
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      By sourcing, creating, and calculating hundreds of property risk-related
      data elements in milliseconds, our AI engines keep the process fast and
      easy for you. Machine learning then ensures the highest degree of accuracy
      before our MaaS engine connects you with global insurance markets. All of
      this in the blink of an eye.
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      It’s this insurance technology that allows us to keep your price accurate
      and your property covered. Put the power of Neptune to the test. It takes
      just seconds to see what you should really be paying to protect your
      property.
    </p>
  </section>

  <CallToAction :baseUrl="data.baseUrl" />
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToAction from '@/components/CallToAction.vue'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'

import { reactive, ref } from 'vue'
const { data } = defineProps(['data'])
const benefits = [
  'Medical insurance',
  '401(k) Match',
  'Dental Insurance',
  'Unlimited PTO',
  'Vision Insurance',
  'Training & Continuing Professional Education',
  'Life Insurance',
  'Paid Community Volunteering Opportunities',
  'Short-Term Disability',
  'Long-Term Disability',
]
</script>
